/*     site-3.9     */


/* html {
	margin: 0;
	padding: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	overflow: auto;

}
div	{
	display: block;
	margin: 0;
	padding: 0;

      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
}

img {
	max-width: 100%;
}
*/



/* BACKGROUND — OFF-WHITE */
.webt_ui_back_1 {
      background: RGB(250,250,250);
}
/* BACKGROUND — GREEN */
/* .list li:hover, .list_actions>li:not(.nav_select):hover,.uiNavDiv .list li:hover, */
.webt_ui_back_2, .list >li.highlight, .list_actions>li:not(.nav_select).active,.webt_ui_tooltip,#webt_ui_foot{
      background: #0aadff;    /* 100,159,19 */
}
/* BACKGROUND — LIGHT GRAY */
.webt_ui_back_3 {
      background: rgb(237,237,237);
}

/* other light gray*/
.webt_ui_back_3a{
  background:#c5c6c7;
}

/* other light gray 2*/
.webt_ui_back_3b{background:#A1A3A5;}
.webt_ui_back_3c{background:rgb(210,210,210);}

/* BACKGROUND — OFF-WHITE 2 */
.webt_ui_back_4, .button_icon.active, .button_icon.active:hover{
      background: #F0F0F0 ; /*should be same color as ui_obox*/

      /*background: #EEE;*/
      /*background: rgba(242, 242, 242, 1);*/
}
/* BACKGROUND — WHITE */
.webt_ui_back_5, .list >li {
      background: #FFF;
      background: rgba(255, 255, 255, 1);
}

/* Light Blue */
.webt_ui_back_6{background:#b6edff;}      /* b6edff EAFAFF*/

/* Med Blue */
.webt_ui_back_7{background:#a4e2f6}     /*#a4e2f6;}   D3F4FF*/

/* header blue*/
.webt_ui_back_8{background:rgb(0,180,255);}

/* Dark Blue */
.webt_ui_back_9{background:rgb(11,148,228)}

/* Dark Gray */
.webt_ui_back_10{background:rgb(26,26,26);}

/* Darker Gray */
.webt_ui_back_11{background:#333;}

/* Popup Gray */
.webt_ui_back_12{background:rgb(92,92,92);}

/* Sel Gray */
.webt_ui_back_13{background:rgb(170,170,170);}


.webt_ui_back_14{background:rgb(77,77,77);}
.webt_ui_back_15{background:#5C5C5C;}
.webt_ui_back_16{background:rgb(29,52,119)}
.webt_ui_back_17{background:rgb(255,87,87);}
.webt_ui_back_18{background:rgb(120,120,120);}  /* mid-darkish gray */
/*UI green */
.uiColor1{background-color:#0aadff;}

/* .list li:hover, .list_actions>li:hover, .uiNavDiv .list li:hover,.webt_toggle:hover>.toggle_div,.button_icon:hover span,.uiNavDiv .list li:hover i.fa-circle-o, .uiNavDiv .list li:hover i.fa-circle*/
/* COLOR — WHITE */
.webt_ui_color_1, .list >li.highlight, .list_actions>li.active{
      color: #FFF;
      color: rgba(255, 255, 255, 1);
}

/* COLOR — BRAND COLOR */
.webt_ui_color_2, .list_actions>li, .uiNavDiv .list i.fa-circle-o, .uiNavDiv .list i.fa-circle, .link_actions {
      color: #0aadff;   /* 163,218,87 */
}
/* COLOR — BLACK */
.webt_ui_color_3, .list_actions>li.notactive, #link_edit_list>li.notactive {
      color: #404040;
      color: rgba(64, 64, 64, 1);
}

/* COLOR - ALERT */
.webt_ui_color_4{
   color:rgb(190, 46, 84)
}
/* COLOR-LIGHTER GRAY */
.webt_ui_color_5{color:#AAA;}

/* Light Blue */
.webt_ui_color_6{color:#b6edff;}

/* Med Blue */
.webt_ui_color_7{color:#a4e2f6;}

/* COLOR-LIGHT GRAY */
.webt_ui_color_8{color:#DEDEDE;}

/* COLOR-LIGHT BLACK */
.webt_ui_color_9{color:#777;}

/* COLOR-MED BLACK */
.webt_ui_color_10{color:#45474d;}

/* LIGHTER GREEN */
.webt_ui_color_11{color:rgb(155,231,54)}  /*163,218,87 15,196,79     126, 184, 214 */

.webt_ui_color_12{color:#7ADAFF;}

.overflow_vis,.overflow_vis2 {
	overflow: visible;
}

.wt_main_pagetitle {  /*  PAGE TITLE  */
	font-weight: 300;
	font-size: 34px;
	letter-spacing: .01em;
	word-spacing: .01em;
	text-align: center;
	font-style: normal;
	line-height:36px;
}

::selection {
	background: #212121;
    background: rgba(33, 33, 33, 1);
    color: #FFFFFF;
    color: rgba(255, 255, 255, 1);
}


#wrapper {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0px;
	overflow-y:hidden;
/*	overflow-x: hidden;
	overflow-y: scroll;*/
  		-webkit-overflow-scrolling: touch;
/*    max-width: 1400px;*/
	background: #FFF;
}

.row {
	clear: both;
	overflow: auto;
}

.box_1, .box_2, .box_3, .box_4, .box_5,.box_row2, .box_6, .box_7, .box_8, .box_9 {
	padding: 20px;
	display: inline-table;
	vertical-align:top;
    position: relative;
    margin: 0;
}

.box_1 {
	width: 16.5%;
}
.box_2 {
	width: 33.2%;
}
.box_3{
	width: 50%;
}
.box_4 {
	width: 66.66666666666%;
}
.box_5,.box5_m {
	width: 83.33333333333%;
}
.box_row2{
	width:90%;
}
.box_6 {
	width: 100%;
}

.scooch_13 {margin-left:9%;}
.scooch_row2 {margin-left:7%;}

.scooch_quart {
	margin-left: 4%;
}
.scooch_half {
	margin-left: 8.33333333333%;
}
.scooch_1 {
	margin-left: 16.66666666666%;
}
.scooch_1a {
	margin-left: 12%;
}
.scooch_2 {
	margin-left: 33.33333333333%;
}
.scooch_3 {
	margin-left: 50%;
}
.scooch_4 {
	margin-left: 66.66666666666%;
}
.scooch_5 {
	margin-left: 83.33333333333%;
}

.spaced_top {
	padding-top: 40px;
}
.spaced_top_60 {
	padding-top: 60px;
}
.spaced_top_80 {
	padding-top: 80px;
}
.spaced_top_120 {
	padding-top: 120px;
}
.spaced_top_160 {
	padding-top: 160px;
}
.spaced_top_foot {
	margin-top: 0px;
}
.not_spaced_top {
	padding-top: 0px;
}
.spaced_bottom {
	padding-bottom: 40px;
}
.spaced_bottom_80 {
	padding-bottom: 80px;
}

.feature_pan_cont{
	padding:10px;
    max-width: 1200px;
    margin: 40px auto 90px;
    background:rgb(249,249,249);
}

.feature_pan_div{
	display:inline-table;
	vertical-align:top;
	width:50%;
	padding:20px;
    box-sizing: border-box;
}

.feature_half_pan{
	min-height:225px;
}

.feature_pan{
	min-height:440px;
	background:#FFF;
	border:1px solid #F0F0F0;
	margin-bottom:30px;
}

.graphic_cont_div{
	width:800px;
	margin:auto;
	color:#FFF;
	box-sizing:border-box;
}
.way_title{font-size:24px;color:#404040;margin-bottom: 6px;}
.way_tile{padding: 19px 10px 10px;text-align:center;height: 92px;}
.way_author{background:#22aefd;width:96px;}
.way_book{background:#808080;width:168px;}
.way_pub{background:#454545;width:536px;}
.way_authorw{background:#22aefd;width:160px;}
.way_wispbook{background:#454545;width:160px;}
.way_student{width:480px;color:#CCC;border-top:2px solid #CCC;border-right:2px solid #CCC;border-bottom:2px solid #CCC;}
.way_entry{font-size:18px;}
.way_asterisk{color:#aeaeae;font-size:18px;margin-top:8px;}

.promo_res{display:none;margin-top:20px;}
.promo_res1{font-size:17px;}
.promo_res2{font-size:14px;margin-top:5px;}

@media (max-width: 786px) {

	.box_1, .box_2, .box_3, .box_4, .box_5, .box_5m, .box_6 {
		padding: 20px;
		width: 100%;
	}
	.spaced_top {
		padding-top: 40px;
	}
	.spaced_top_80,.spaced_top_60 {
		padding-top: 0px;
	}
	.scooch_1, .scooch_1a,.scooch_2, .scooch_3, .scooch_4, .scooch_5 {
		margin-left: 0px;
	}

}

.desktop {	display: block;	}
.tablet {	display: none;	}
.mobile {	display: none;	}
.notmobile {	display: block;	}

@media (max-width: 1080px) {
	.desktop {	display: none;	}
	.tablet {	display: block;	}
	.mobile {	display: none;	}
	.notmobile {	display: block;	}
}
@media (max-width: 500px) {
	.desktop {	display: none;	}
	.tablet {	display: none;	}
	.mobile {	display: block;	}
	.notmobile {	display: none;	}
}


/*
body {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif; 
	font-weight: 400; 
	font-style: normal;
	font-size: 12px;
	text-align: left;
	color: rgba(43, 43, 43, 1);
}

p {
	margin: 0;
	padding: 0;

}

*/

.life_div .sub_text{
    font-size: 15px;
    font-weight: 300;
    line-height: 26px;
    width:470px;

    margin: 0px 0px 25px 0px;
    padding: 0;

}

#subscribe_info_div{margin:45px 0px 40px 0px;font-size: 14px;line-height: 20px;}
.book_results{font-size: 24px;margin-bottom: 25px;}

.centered {
	text-align: center;
}
#signin_subtitle2{font-size:12px; display:none;}
#school_div,#position_div{margin-top:35px;}
#ex1_2{
	width:500px;
	margin: auto;
}
#ex1_datacom{
	width:440px;
	margin: 30px auto 0px;
}
#ex1_4{
	width:350px;
	margin: 30px auto 0px;
}
#ex1_combo{
	width:1000px;
	margin: 30px auto 0px;
}
#ex_1,#ex_2,#ex_3,#ex_4,#ex_5,#ex_6{
	width:1000px;
	margin: 30px auto 0px;
}

#startBut, #startBut1{
	margin:70px auto 0px;
}

.button{
	background: rgb(43,43,43);
	width: 100%;
	margin: 30px -2px 0px;
}
.prev_but_div{
    position: absolute;
    right: -93px;
    color: #0aadff;
    cursor: pointer;
    font-size: 13px;
    width: 63px;
    text-transform: none;
    top: 12px;
}
.button{width:400px;
	font-size: 16px;
	margin-top:45px;
}

#new_wisp_cont{
    width: 320px;
    margin: 20px auto;
    background: #FFF;
    padding: 50px 60px;
    position:relative;
}
.newWispUL{
    font-size: 14px;
    color:#888;
}

.sel_cont_pan{display:inline-table;vertical-align:top;}

.create_row{width:320px;margin:35px auto 0px;;}
.button1 {
	width: 145px;
	font-size: 18px;
	display:inline-table;
	vertical-align:top;
}
.button{
	background: #0aadff;
}
.button,.button1 {
	text-align:center;
	line-height:30px;
	letter-spacing: .1em;
	word-spacing: .1em;
	height: 30px;
	padding: 4px 2px;   /* want  4 px for button on home page */

	cursor: pointer;

	box-shadow: none;
	outline: none;
	border-radius: 20px;
	border: none;
	color: rgba(255, 255, 255, 1);

      -webkit-box-sizing: content-box;
         -moz-box-sizing: content-box;
              box-sizing: content-box;

  	-webkit-transition: all ease-out 50ms;
       -moz-transition: all ease-out 50ms;
     		transition: all ease-out 50ms;
}
.button:hover {
	background: rgba(53, 53, 53, 1);

  	-webkit-transition: all ease-out 150ms;
       -moz-transition: all ease-out 150ms;
     		transition: all ease-out 150ms;
}
.button1:hover{
	background: rgb(43,43,43);
  	-webkit-transition: all ease-out 150ms;
       -moz-transition: all ease-out 150ms;
     		transition: all ease-out 150ms;
}

.input_active_hint {
	position: absolute;
	left: -10px;
	top: -16px;
	opacity: 0;
	color: #444;

  	-webkit-transition: all ease-out 50ms;
       -moz-transition: all ease-out 50ms;
     		transition: all ease-out 50ms;
}
div.input_hint {
	position: absolute;
	left: 18px;
	top: 0px;
	line-height: 40px;
	opacity: 1;
	z-index: 1;
	color: #666;
}
div.input_active_hint {
	font-size: 10px;
	line-height: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .08em;
	word-spacing: .08em;
}

#nav.hamburger div, #nav.close div, .close div {
	position: absolute;
	left: 10px;
	top: 20px;
	width: 14px;
	height: 2px;
	margin: 0;

	border-radius: 1px;
	background: #04abff;
	background: rgba(4, 171, 255, 1);
}

#nav, .close {
	position: relative;
	width: 25px;
    height: 25px;
	z-index: 105;
    cursor: pointer;
}

#nav div, .close div {
  	-webkit-transition: all ease-in-out 150ms, width ease-in-out 150ms, top ease-in 30ms, left ease-in 110ms;
    -moz-transition: all ease-in-out 150ms, width ease-in-out 150ms, top ease-in 30ms, left ease-in 110ms;
	transition: all ease-in-out 150ms, width ease-in-out 150ms, top ease-in 30ms, left ease-in 110ms;
}

#nav.close div.one_one, #nav.close div.one_two, #nav.close div.three_one, #nav.close div.three_two, .close div.one_one, .close div.one_two, .close div.three_one, .close div.three_two {
	width: 6px;
}
#nav.close div.one_one, .close div.one_one {
	left: 10px; /* find wisp book box needed 9/12 */
	top: 13px;
	-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
			transform: rotate(45deg);
}
#nav.close div.one_two, .close div.one_two {
	left: 21px;
	top: 13px;
	-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
}
#nav.close div.two, .close div.two {
	opacity: 0;
  	-webkit-transition: all ease-out 90ms;
       -moz-transition: all ease-out 90ms;
     		transition: all ease-out 90ms;
}
#nav.close div.three_one, .close div.three_one {
	left: 10px;
	top: 24px;
	-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
}
#nav.close div.three_two, .close div.three_two {
	left: 21px;
	top: 24px;
	-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
			transform: rotate(45deg);
}


.wt_inputdiv.active div.input_active_hint, div.wt_textareadiv.active div.input_active_hint {
    opacity: 1;
	left: 0px;
	color: rgba(64, 64, 64, 1);

  	-webkit-transition: all 150ms ease-out 400ms;
       -moz-transition: all 150ms ease-out 400ms;
     		transition: all 150ms ease-out 400ms;
}
.wt_inputdiv.active div.input_hint, div.wt_textareadiv.active div.input_hint {
    opacity: 0;
    left: 40px;
}

.wt_inputdiv{height: 40px;}
div.wt_textareadiv {height: 120px;}


/*.wt_inputdiv:hover, div.wt_textareadiv:hover {
	background: rgba(245, 245, 245, 1);
  	-webkit-transition: all ease-out 150ms;
       -moz-transition: all ease-out 150ms;
     		transition: all ease-out 150ms;
}

.wt_inputdiv::before,div.wt_textareadiv::before {		
	content:'';
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 0%;
	padding: 0px 0px 0px 4px;
    height: 100%;
	background: rgb(250,250,250);

  	-webkit-transition: all ease-out 50ms;
       -moz-transition: all ease-out 50ms;
     		transition: all ease-out 50ms;
}
.wt_inputdiv:hover::before, div.wt_textareadiv:hover::before {
	padding: 0px 0px 0px 8px;

  	-webkit-transition: all ease-out 150ms;
       -moz-transition: all ease-out 150ms;
     		transition: all ease-out 150ms;
}
.wt_inputdiv.active::before, div.wt_textareadiv.active::before {
	width: 100%;
	padding: 0px;

  	-webkit-transition: all ease-out 250ms;
       -moz-transition: all ease-out 250ms;
     		transition: all ease-out 250ms;
} */
.wt_inputdiv.not_required::before, div.wt_textareadiv.not_required::before {
	background: rgba(166, 166, 166, 1);
}

.wisp_in{display:inline-table;vertical-align:top}
.wisp_in_mid, .wisp_in_mid2{display:inline-table;vertical-align:middle}
.wisp_nosel{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.wt_copyright{position:absolute;bottom:20px;width:100%;text-align:center;font-size:12px;color: #BBB;z-index: 1;}
.wt_doc_page_div{position:relative;}

.banner2 .sel{}
.banner2 .sel_cont{width:320px;}
.banner2 .sel_cont2{width:320px;margin-left:60px;}
.banner2 .sel_cont3{width:320px;margin-left:60px;}
.quick_wisp_title{margin:40px 0px;}
.taskHeader{margin-left:30px;}
.cal_day_cont{padding-left: 20px;background:#FFF;}

.wt_input, .wt_textarea {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif; 
	font-weight: 400; 
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
}

.startBut{
	font-style:normal;
	text-align: center;
    line-height: 36px;
    letter-spacing: .1em;
    word-spacing: .1em;
    color: #FFF;
    height: 36px;
    padding: 4px 2px;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border-radius: 30px;
    border: solid 2px white;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: all ease-out 50ms;
    -moz-transition: all ease-out 50ms;
    transition: all ease-out 50ms;
    width: 310px;
    font-size: 14px;
    font-weight:700;
    margin: 50px 0px 40px;
    text-transform: uppercase;
}
.startBut:hover {
    color: rgba(51, 51, 51, 1);
    background: rgba(255, 255, 255, 1);
}

.wisp_works_div{width:990px;margin:auto;}
.wisp_works{width:320px;text-align:center;}
.works_text{
	font-weight:500;
	font-style:normal;
	text-transform:uppercase;
	font-size:15px;
	text-align:center;
    width: 225px;
    margin:auto;
}
.works_text_doc{
	font-weight:500;
	font-style:normal;
	font-size:15px;
	text-align:center;
    width: 225px;
    margin:auto;
    line-height:135%;
}
.footer_header{font-weight:600;margin-bottom:10px;text-transform: uppercase;}
.wisp_app{margin-bottom:5px;cursor:pointer;}

.page_ex_cont_div{margin:120px auto 0px;}
.page_ex_div{display:inline-table;vertical-align:top;margin:20px;}
.page_ex_div img{}
.page_ex_div_large{width: 1000px;
    vertical-align: top;
    margin: 20px auto;}
.page_ex_div_vert{width:530px;display:inline-table;vertical-align:top;margin:20px;}
.subtitle_h1{
	font-size:34px;
	font-weight:300;
	margin-top:5px;
	line-height:130%;
}

.subtitle_h2{
	font-size:16px;
	font-weight:300;
	margin:15px auto;
	line-height:160%;
}

.icon_active{
	border:1px solid #FFF;
	background: #0aadff;
	color:#FFF;
}

.icon_active::after{
    content: '';
	position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-left: 25px solid rgba(4, 171, 255, 1);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    left: -35px;
    top: 24px;
    cursor:pointer;
}

.intel_ex{
	font-size:14px;
	font-weight:300;
	margin-bottom: 20px;
}

.intel_ex_title{
	font-size:24px;
	margin-bottom: 15px;
	line-height: 120%;
}

.wisp_circle{
	width: 30px;
	height: 30px;
	background: #0aadff;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;	
	margin-left: 30px;
    margin-bottom: 5px;
}
.intel_icon{font-size:27px;
	width:90px;
    display: inline-table;
    vertical-align: middle;
    text-align: center;
    cursor:pointer;
}
.intel_div{    
	margin-top: 5px;
    margin-right: 50px;
    padding: 10px 11px 5px 0px;
    cursor:pointer;
}
.intel_h1{        font-size: 14px;
    text-transform: uppercase;
        margin-top: 6px;
}
.intel_h2{font-size: 14px;
    font-weight: 300;
    display: inline-table;
    vertical-align: middle;
    margin-left: 10px;}

.lifeItem {
    font-size: 17px;
    font-weight: 300;
    line-height: 30px;
    margin: 0px 0px 10px 0px;
    padding: 0;
}
#startBut1 {width: 350px;}
.header_action{font-size:42px;color:#0aadff;margin-bottom: 85px;}
.header_num{font-size:86px;}

.lifeItem i{
	font-size:10px;
}

.life_col1{display:inline-table;width:50%;}


#wisp_desc_title{
	font-size: 24px;
    font-weight: 600;
    margin-top: 40px;    
}
#wisp_desc_title_w{
	font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
}
#wisp_desc, #wisp_desc_w{font-size:14px;text-align:left;text-transform: none;}
#wisp_desc li, #wisp_desc_w li{margin-top:15px;}
#wisp_desc li{font-size:18px;}
#wisp_desc ul, #wisp_desc_w ul{list-style: none;padding:0px;}
#wisp_desc i{font-size:20px;width:30px;}
#wisp_desc_w i{font-size:20px;width:35px;}

.wispy{	font-family: "Idlewild SSm A", "Idlewild SSm B";
	font-style: normal;
/*	font-weight: 600;
    font-size: 40px;
    color: #0aadff;*/
}
.wispy2{
    color: #0aadff;
}
.wisp_page_type_div{width:1030px;margin:20px auto;text-align:center;}
.wisp_page_type{
    display: inline-table;
    vertical-align: top;
    font-size: 14px;
    margin: 20px 10px;
    background: #EEE;
    color: #777;
    padding: 10px 20px;
    border-radius: 40px;
}
.wisp_page_divide {
    display: inline-table;
    font-size: 40px;
    margin-top: 11px;
}
.wisp_works_cont{
	text-align: center;
	position:relative;
    background: #EEE;
    color: #777;
    padding: 10px 20px;
    border-radius: 40px;
    margin: 20px 00px;
}
.wisp_works_num{display: inline-table;
    vertical-align: middle;
    font-size: 30px;
    margin-right:20px;
}
.wisp_works_text{
	display: inline-table;
    vertical-align: middle;
    font-size: 16px;
}
.wisp_page_divide{display:inline-table;font-size: 40px;
    margin-top: 11px;}

span.logo {
	font-family: "Idlewild SSm A", "Idlewild SSm B";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 60px;

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;

	color: #FFF;
}

span.logo span {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif; 
	font-size: 8px;
	line-height: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .08em;
	word-spacing: .08em;

	position: relative;
	top: -9px;
	left: 5px;
	margin: 0 0 0;

	color: #595959;
	color: rgba(89, 89, 89, 1);
}

#wisp_img_box{width:500px;}
.home_img_x{width:500px;margin:10px 0px 0px -20px;}
.home_img_w{width:450px;}
.home_arr{left:45%;width:40px;}
.wt_intell_i{margin:15px 15px 0px 0px;}
#wisplist_div{width:743px;margin:auto;}

.wisp_circle2{
	width: 300px;
	height: 300px;
	position:relative;
	border:1px solid #FFF;
	-moz-border-radius: 150px;
	-webkit-border-radius: 150px;
	border-radius: 150px;
	-webkit-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
			transform: rotate(135deg);
}

.wisp_circle2:after{
    content: '';
    position: absolute;
    top: -15px;
    left: 140px;
    z-index: 0;
    width: 30px;
    height: 30px;
    background: rgba(4, 171, 255, 1);
    border-radius: 50px;
}

.circ2_people{
	position:absolute;
	z-index:0;
	top:60px;
	left:115px;
	color:#AAA;
	font-size:44px;
}

.circ2_excel{
	position:absolute;
	z-index:0;
	top:360px;
	left:124px;
	width:50px;
	height:70px;
	border:1px solid #DDD;
	background:#FFF;
}
.circ2_excelx{
    position: absolute;
    top: 15px;
    left: 16px;
    font-size:36px;
    z-index: 0;
    font-weight:300;
    color:rgb(32,112,68);
}

#circ2_rows{margin:50px 10% 0px;}

.circ2_row{
	width:99%;
	height:8px;
	margin:-1px auto 0px;
}
.circ2_cell{
	width:33%;
	height:8px;
	border:1px solid #AAA;
	display:inline-table;
}

.life_div{margin-bottom:35px;margin-right:45px;}

.nav_list {
	margin: 0 -20px 0px 10px;
	list-style-type: none;
    position: relative;
    z-index: 5;
	float: right;
	padding-right: 40px;
    box-sizing: border-box;
}

.nav_list a li:hover, .nav_list li:hover {
	color: #FFF;
	color: rgba(255, 255, 255, 1);
	
  	-webkit-transition: all ease-out 150ms;
       -moz-transition: all ease-out 150ms;
     		transition: all ease-out 150ms;
}

#nav_positioning, .close_positioning {
	position: fixed;
	right: 10px;
    top: 5px;
    margin: 0;
    z-index: 105;
    display: none;
}

#nav.hamburger:hover div{
	background: #FFF;
	background: rgba(255, 255, 255, 1);
}
#nav.close div {
	background: #04abff;
	background: rgba(4, 171, 255, 1);
}
#nav.close:hover div {
	background: #FFF;
	background: rgba(255, 255, 255, 1);
}


#nav div.one_one {
	left: 10px;
	top: 10px;
}
#nav div.one_two {
	left: 22px;
	top: 10px;
}
#nav div.two {
	width: 26px;
	top: 19px;
	opacity: 1;
  	-webkit-transition: all ease-out 175ms;
       -moz-transition: all ease-out 175ms;
     		transition: all ease-out 175ms;
}
#nav div.three_one {
	left: 10px;
	top: 28px;
}
#nav div.three_two {
	left: 22px;
	top: 28px;
}

#supp_text{margin-top:20px;}




#pageDlgCont{background:white;padding:20px;margin:auto;margin-top:50px;width:330px;} 
.pubBut {width:150px;text-align:center;padding:4px;font-size: 16px;cursor:pointer;}


/*     H O M E     */

.icon_div{
	font-size: 24px;
    opacity:.5;
    margin-bottom: 10px;
}

.banner2 {
	position: relative;width:100%;
}
.banner2_panel{display:inline-table;vertical-align:top;width:50%; height:500px;}
.panel1{background:url('../images/professor.jpg') no-repeat;background-size:cover;}
.panel2{background:url('../images/student.jpg') no-repeat;background-size:cover;}
.banner2_ipanel{margin:100px 10% 80px 50%;background:rgba(255,255,255,.7);width:40%;height:500px;min-width:307px;max-width:420px;}
.banner2_title{text-align:center;font-size:32px;text-transform:uppercase;padding:25px 0px 0px;}
.banner2_item_text{font-size: 24px;
    margin: 25px 20px 10px;}
.banner2_item_subtext{    font-size: 12px;
    margin: 0px 20px 20px;}
.banner2_action{display: inline-table;
    vertical-align: top;
    width: 48%;
    text-align: center;
    font-size: 14px;
    color: #0aadff;
    font-style: italic;
    background: rgba(255,255,255,.8);
    padding: 10px 0px;
    cursor:pointer;
}

.inner_banner{
	padding-top:10px;
	margin: auto;
}

.wisp_right{position:absolute;
	z-index:1;
	right:5px;
    top: 70px;
    color: #888;
    font-size: 36px;
    cursor:pointer;
    padding:10px;
    display:none;
}
.wisp_left{position:absolute;
	z-index:1;
	left:5px;
    top: 70px;
    color: #888;
    font-size: 36px;
    cursor:pointer;
    padding:10px;
    display:none;
}

.banner_pric {
	position: relative;
	z-index: 0;
	padding: 160px 0 100px;
	margin: 0 0 40px;
	background: none;
}

.banner_items,.banner_items_w{
	max-width: 900px;
}
.banner_items{
    margin: 75px auto 20px;
}
.banner_items_w{
    margin: 75px auto 20px;
}

.banner_items_w > div{
	display: inline-table;
    text-align: center;
    width: 22%;
    font-size: 16px;
    text-transform: uppercase;
    vertical-align: top;
/*    opacity: .5; */
}

.banner_item_w i{
    margin-top: 37px;
    font-size: 30px;
    margin-bottom: 15px;	
}

.banner_items > div{
	display: inline-table;
    text-align: center;
    width: 33%;
    font-size: 20px;
    text-transform: uppercase;
    vertical-align: top;
}

.banner_item,.banner_item_w{
	width: 150px;
    height: 150px;
    border: 1px solid #CCC;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    color:#333;
    margin:auto;
    cursor:pointer;
    background:#FFF;
}

.banner_item_t{
    line-height:144px;
}
.banner_item_t2{
    padding-top: 47px;
}

.banner_items .active,.banner_items_w .active{
    background: #0daeff;
    color:#FFF;
}

.banner1a{width:100%;height:90px;background:#777;color:#FFF;}

/*.banner::after {
    content: '';
    position: absolute;
    bottom: -9%;
    left: -17%;
    z-index: -1;
    width: 115%;
    height: 0;
    padding-bottom: 100%;

    background: rgb(242, 242, 242);
    border-radius: 50%;
}*/

.head_h4{
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 10px;
}

.banner h1,.banner_pric h1 {
	position: relative;
	z-index: 10;

/*	color: #2b2b2b;
	color: rgba(43, 43, 43, 1);*/

}
.homeWispH1Span{font-size:43px;}
.wispTest{position: absolute;
    left:-1000px;
    height: auto;
    width: auto;
    white-space: nowrap;
}
.homeWispH1Div,.homeWispH4Div{position:relative;}
.homeWispH1Div{
    margin: auto;	
    text-align: center;
}
.homeWispH1Div_page{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 500;
    width: 910px;
    margin: auto;	
    text-align: center;
}
.homeWispH1Div_doc{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 500;
    width: 735px;
    margin: auto;	
    text-align: center;
}
.homeWispH1Div_log{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 500;
    width: 800px;
    margin: auto;	
    text-align: center;
}

.homeWispH4Div{margin-left: 30px;}

.banner_pric::after {
    content: '';
    position: absolute;
    bottom: 0%;
    left: -17%;
    z-index: -1;
    width: 101%;
    height: 0;
    padding-bottom: 100%;

    background: rgb(242, 242, 242);
    border-radius: 50%;
}

.banner h4, .banner_pric h4 {
	position: relative;
	    margin: 20px auto 120px;
}

.wisp_items{
	width: 817px;
    margin: 60px auto 75px;
}
.wisp_item{display:inline-table;
	vertical-align:middle;
	text-transform: uppercase;
	font-size: 15px;
    width: 119px;
    height: 127px;
    color: #777;
    margin: 0px 20px;
    padding: 22px 0px 0px;
    text-align: center;
    cursor: pointer;
}
.wg_icon{font-size:30px;margin-bottom:5px;}

.wisp_item.selected{
    border: rgba(10,190,255,.2) 1px solid;
    background: #FFF;
}
.wisp_item.selected:before {
	content: '';
    position: absolute;
    z-index: 0;
    right: -18px;
    top: -17px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(10,173,255,.3);
}

.sect3 {
	position: relative;
	z-index: 0;
	padding: 160px 0 120px;

	background: #FFF; /* Old browsers */
}

.smart_bubble{
	width: 320px;
	display:inline-table;
	vertical-align:top;
	margin-left:80px;
}

.smart_bub_title2{
	text-align:center;
	margin:50px auto 0px;
    font-size: 18px;
    line-height: 150%;
    font-weight: 600;
}
.smart_bub_body{
	font-size: 14px;
    text-align: center;
    margin: 20px 35px;
    /* width: 320px; */
    font-weight: 300;
    line-height: 150%;
}

.feature_icon_div{text-align:center;margin-top:50px;}
.feature_icon:before{
    font-size: 100px;
    color:#0daeff;
    background: -webkit-gradient(linear, left top, left bottom, from( #7df1fe), to(#0daeff));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial; /* reset Font Awesome's display:inline-block */
}
.feature_icon_div2{
    font-size: 180px;
    line-height:100%;
    color:#0daeff;
    background: -webkit-gradient(linear, left top, left bottom, from( #7df1fe), to(#0daeff));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
/*    display: initial;*/ /* reset Font Awesome's display:inline-block */
}

.wisp_grid {
	width: 31.3333333%;
	min-height: 160px;
	margin: 1%;
	padding: 20px 20px 30px;
	float: left;

	background: rgba(255, 255, 255, 1);
	box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, .03), 
				1px 1px 0px 0px rgba(0, 0, 0, .03);
}

.wisp {
    width: 120px;
    height: 120px;
    margin: 0px auto;
    border-radius: 80px;
    background: rgba(173,238,255,.7);   /*rgba(4, 171, 255, .95);*/
    -webkit-transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
       -moz-transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
        transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;

}

.wisp_m_icon2{
    position: absolute;
    top: 19px;
    left: 36px;
    z-index: 10;
    font-size: 56px;
    opacity: .08;
}

.check_option {
	padding: 10px 20px;
	cursor: pointer;
}
.check_option_back {
	width: 100%;
	padding: 15px 20px;

	cursor: pointer;
    border-radius: 2px;

	background: rgba(255, 255, 255, 1);
	box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, .03), 
				1px 1px 0px 0px rgba(0, 0, 0, .03);
}
.check_option_back p span.check {
	position: relative;
	display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0px 12px -3px 0;
    background: none;
    border-radius: 50px;
    box-shadow: 0 0 0 1px rgba(4, 171, 255, 1);
}
.check_option_back p span.check::after {
	content: '';
    position: relative;
    left: 8px;
    top: 8px;
    display: block;
    width: 0px;
    height: 0px;
    margin: 0px 0 0 0px;
    background: rgba(4, 171, 255, 1);
    border-radius: 50px;
    opacity: 0;

	  	-webkit-transition: all ease-out 100ms;
	       -moz-transition: all ease-out 100ms;
	     		transition: all ease-out 100ms;
}
/*.check_option_back:hover p span.check::after,*/
.check_option_back p span.checked::after
 {
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    opacity: 1;

	  	-webkit-transition: all ease-out 40ms;
	       -moz-transition: all ease-out 40ms;
	     		transition: all ease-out 40ms;
}

.wisplist_spacing {    
	padding: 0 0 0 30px;
}

.wisplist_container {    

	background: rgba(0, 0, 0, .05);
    margin: 0 0 0 0;
    border-radius: 10px;
    padding: 60px;
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 1) inset, 
    			0 0 20px 0 rgba(0, 0, 0, .08);
}

.wisplist {
	list-style-type: none;
    margin: 294px 0 0 -15px;
    padding: 0;
}
.wisp_icon {
    display: inline-table;
    vertical-align:top;
    position: relative;
    width: 50px;
    height: 60px;
    margin: 0 15px 40px 15px;
    text-align: center;
    font-size: 20px;
    line-height: 40px;

    color: #FFF;
    color: rgba(0, 0, 0, .2);
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 30px 0px rgba(0, 0, 0, .03), 
    			1px 1px 0px 0px rgba(0, 0, 0, .03);
}

.wisp_icon i.fa {
	line-height: 60px;
}
.wisp_icon::before {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 8px;
    line-height: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .08em;
    word-spacing: .08em;
    text-align: center;
    width: 100px;
    left: 50%;
    position: absolute;
    bottom: -20px;
    margin: 0 0 0 -50px;
    color: #b4b4b4;
    color: rgba(180, 180, 180, 1);
}
.wisp_icon.wisp_icon_form::before {
	content: 'Form';
}
.wisp_icon.wisp_icon_message::before {
	content: 'Message';
}
.wisp_icon.wisp_icon_report::before {
	content: 'Report';
}
.wisp_icon.wisp_icon_time::before {
	content: 'Time';
}
.wisp_icon.wisp_icon_calendar::before {
	content: 'Calendar';
}
.wisp_icon.wisp_icon_library::before {
	content: 'Library';
}
.wisp_icon.wisp_icon_merge_word::before {
	content: 'Word Merge';
}
.wisp_icon.wisp_icon_merge_excel::before {
	content: 'Excel Merge';
}
.wisp_icon.wisp_icon_chart::before {
	content: 'Chart';
}
.wisp_icon.wisp_icon_collab::before {
	content: 'Collaborate';
}

.wisp_icon.wisp_icon_team::before {
	content: 'Team';
}
.wisp_icon_2.wisp_icon_library::before {
	content: 'Library';
}
.wisp_icon_2.wisp_icon_word::before {
	content: 'Word';
}
.wisp_icon_2.wisp_icon_excel::before {
	content: 'Excel';
}
.wisp_icon_2.wisp_icon_word::before {
	content: 'Word';
}
.wisp_icon_2.wisp_icon_picture::before {
	content: 'Picture';
}
.wisp_icon_2.wisp_icon_video::before {
	content: 'Video';
}
.wisp_icon_2.wisp_icon_work::before {
	content: 'Work';
}
.wisp_icon::after {
	content: '';
    position: absolute;
    right: -11px;
    top: 6px;
    display: block;
    width: 20px;
    height: 20px;
    margin: 0px 0 0 0px;
    background: rgba(4, 171, 255, .95);
    border-radius: 50px;
    z-index:1;
}

.popWispRow{margin-bottom:20px;}
.popWispBox{width:300px;display:inline-table;}
.popWispBox2{background:rgb(250,250,250);padding:10px;margin-right:20px;height:200px;}
.popWispName {text-align:center;font-size:26px;margin-bottom:15px;line-height:120%;width:300px;}
.popWispBox2>div{display:inline-table;vertical-align:top;}
.popFeatureCont{width:190px;margin-left:10px;}
.popFeature{margin-bottom:10px;}



 .gray {
	position: relative;
	z-index: 0;
	padding: 200px 0 260px;
	margin: 0 0 40px;

	background: #0aadff; /* Old browsers */
	background: -moz-linear-gradient(top,  #0aadff 0%, #04abff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #0aadff 0%,#04abff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #0aadff 0%,#04abff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0aadff', endColorstr='#04abff',GradientType=0 ); /* IE6-9 */
 }

#offerBut{
	width:500px;
	font-weight:400;
	font-size:16px;
	position: absolute;
    left: 100px;
}
.offer_tri{position:absolute;z-index:-1;
	top:-10px;left:230px;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 40px solid white;
}
.offer_head{font-weight:600;font-size: 18px;}
.offer_body{text-transform: none;
    font-size: 14px;
    line-height: 132%;
    margin: 0px 20px;}


.basic_header, .premiere_header, .unlimited_header {
  padding: 10px 0px;
  text-align: center;
  width: 215px;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: .08em;
  text-transform: uppercase;
  font-weight: 400;
  vertical-align:top;
}

.p_column{padding:8px;}

.basic_header {
  background: #3addff;
  color: #FFF;
  margin:auto;
}
.premiere_header {
  background: #2acdff;
  color: #FFF;
}
.unlimited_header{
  background: #0aadff;
  color: #FFF;
}

#pricingtable{background:rgb(250,250,250);}

#pricingtable > tbody > tr:nth-child(even) > td{
  background-color: rgb(245,245,245);
}

#pricingtable td{  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #dddddd;
  text-align:center;
}

.price_sign_but{
  color: #FFF;
  text-decoration:none;
  padding: 6px 30px;
  border-radius: 3px;
}

.planBox{width:260px;display:inline-table;vertical-align:top;}
.planBox2{width:260px;background:rgb(242,242,242);height:173px;padding:15px;border-radius:10px;}
.planBoxHead{font-size:26px;margin-bottom:17px;color:#444;}
.planBoxItem{font-size:13px;text-transform:uppercase;color:#777;}
.want_emph{font-weight:600;font-size:14px;}

.want_more{text-align:center;color:#0aadff;cursor:pointer;font-size:13px;}

#neww_alert1_div{top:103px;font-size:13px;}

#forgotpwDiv,#visNotice{cursor:pointer;display:inline-table;}
#forgotpwDiv{width:135px;}

#decisionDiv {
      padding: 25px 50px 30px 30px;
      position: fixed;
      top: 45%;
      margin:auto;
      z-index: 10002;
      font-size:16px;font-weight:bold;
      border:solid 1px;
}
#decisionDiv a{text-decoration:none;}
#decisionDiv p{margin:10px 0px;}

.webt_ui_back_2{background: #0aadff;}


.popHeading{font-size:44px;font-weight:300;margin:40px 0px 60px 0px;line-height:100%;}
#wispDetails{margin-top:45px;}

.oneormore{font-size: 15px;
    text-align: center;
    font-weight: 300;
    color: #333;
    width: 617px;
    margin: 20px auto;
}

.wt_footer{padding:20px 0px 60px;background: rgb(252,252,252);}
.wt_footer:before{
    content: '';
    position: absolute;
    top: -9%;
    left: -17%;
    z-index: 0;
    width: 114%;
    height: 0;
    padding-bottom: 100%;

    background: rgb(242, 242, 242);
    border-radius: 50%;
}
.wisp_div{
	background: #FFF;
    font-size: 9px;
    text-transform: uppercase;
    width: 81px;
    height: 100px;
    text-align: center;
    padding: 15px 5px 5px 5px;
    border: 1px solid rgb(239,239,239);
    line-height: 19px;
	margin: 0px 10px 20px;
	cursor:pointer;
	display:inline-table;
	vertical-align:top;
	color:#888;
}
.wisp_div::after{
content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 0;
    width: 20px;
    height: 20px;
    background: #0daeff;
    border-radius: 50px;
}

#wisp_title_ex{
    font-size: 14px;
    text-align:center;
    margin-top:10px;
}
#wisp_desc_ex{
    text-align:center;
    margin: 0px auto 10px;
    font-size: 14px;
}

.wisp_span{font-size: 12px;
    font-weight: 600;}

.wisp_lineup{
    font-size: 45px;
    text-align: center;
    font-weight: 300;
    color: #333;
    width: 610px;
    margin: 140px auto 0px;
}
.wisp_lineup_sub{
    font-size: 15px;
    text-align: center;
    font-weight: 300;
    color: #333;
    margin: 18px auto 0px;	
    width:500px;
}
.prev_div,.prev_div_w{
	width:400px;
	margin:auto;
}

.inner_prev_div,.inner_prev_div_w{
	margin-top:40px;padding: 30px 30px 30px 30px;background: rgb(240,240,240);	
}
.inner_prev_div{width:890px;}
.inner_prev_div_w{width:430px;}

.tp_li{display:inline-table;vertical-align:top;margin-right: 30px;}
.wisp_img{
	width:220px;
	max-width: 100%;
}
.wisp_img_cont2{
	display:none;
	width:220px;
	margin-right:45px;
	vertical-align:top;
}
.wisp_img_cont2:after{
    content: '';
    position: absolute;
    top: -10px;
    right: -11px;
    z-index: 0;
    width: 30px;
    height: 30px;
    background: rgba(4, 171, 255, 1);
    border-radius: 50px;
}
.wisp_img_cont{
    margin: 40px auto 0px;
}

#offerBut{height:90px;background:#FFF;color:rgb(4, 171, 255);}
.banner_h4{width:77%;}
#footer_box2{padding:80px 0px 0px 0px}

.headerLogoImg {
    margin: 20px 0px 0px 60px;
    width: 80px;
}
#webt_ui_support2 {
    width: 550px;
    margin: 60px auto 0px;
    padding: 20px;
    padding-bottom: 50px;
}

#webt_ui_support2 h2 {
    margin: 0px 0px 20px 0px;
}

#supportTopics {
    margin: 10px 0px 0px 0px;
}

.supportTextArea {
    width: 380px;
}

.newMesInputDiv {
    height: 20px;
    position: relative;
    padding-top: 2px;
    padding-left: 5px;
    background-color: #FFF;
    border: solid;
    border-width: 1px;
    border-color: #CCC;
    width: 400px;
}

/* .newMesInputDiv input {
    width: 500px;
    color: #444;
    height: 24px;
} */

.footer_item {
    margin-bottom: 5px;
    cursor: pointer;
}

@media (max-width: 1700px) {
    .wisplist {margin-top: 205px;}
}

@media (max-width: 1500px) {
	.wisplist {margin-top: 105px;}
	.book_icon{width:190px;}
}

@media (max-width: 1300px) {
	.wisplist {margin-top: 50px;}
	.banner2_ipanel{margin:50px 10% 50px 40%;}
	.book_icon{width:190px;}
}

@media (max-width: 1200px) {
}

@media (max-width: 1100px) {
	.wisplist {margin-top: 20px;}
	.banner h4, .banner_pric h4 {width:90%;}
}

.book_icon {
    background:rgb(77,77,77);
    color:#FFF;
    margin: 0px 20px;
    padding: 40px;
    width: 200px;
    text-align: center;
	-webkit-box-shadow:0 0 10px rgba(77,77,77,.5); 
	-moz-box-shadow: 0 0 10px rgba(77,77,77,.5); 
	box-shadow:0 0 10px rgba(77,77,77,.5);
	box-sizing: border-box;
}
.book_title{font-size:20px;font-weight:600;margin:20px 0px;}
.book_title2{font-size:32px;text-transform:uppercase;font-weight: 600;margin: 0px 0px 25px 0px;line-height: 120%;}
.book_author{font-style: italic;margin: 90px 0px 20px;}
.book_details_div{margin-left: 40px;width:280px;}
.book_details{font-style: italic;    font-size: 13px;}
.book_desc{margin: -15px 0px 15px;font-size: 13px;}
.book_price{margin-top: 20px;font-size: 16px;}
.signin_mes{margin: 15px 0px 30px;background: rgb(77,77,77);color: #FFF;padding: 10px;font-size: 13px;}
#stripeDiv{position: relative;}

.wisp_but{border-radius:25px;text-align:center;cursor:pointer;} /* don't use webt_but anymore - wisp_but is used in getButton() */
.wisp_but:hover{background:#0aadff;}

.dropdown-menu li,#user_menu li, #share_menu li{color:#333;font-size:14px;padding:5px 20px 5px 20px;cursor:pointer;line-height: 120%;}
.dropdown-menu li:hover,#user_menu li:hover, #share_menu li:hover{background-color:#0aadff;color:#FFF;}
.dropdown-menu{position:absolute;top:100%;left:-6px;z-index:9501;display:none;float:left;min-width:160px;padding:5px 0;margin:2px 0 0;list-style:none;background-color:#ffffff;border:1px solid #ccc;border:1px solid rgba(0, 0, 0, 0.2);*border-right-width:2px;*border-bottom-width:2px;-webkit-border-radius:6px;-moz-border-radius:6px;border-radius:6px;-webkit-box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);-moz-box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);-webkit-background-clip:padding-box;-moz-background-clip:padding;background-clip:padding-box;}
.dropdown-menu.pull-right{right:0;left:auto;}
.dropdown-menu .divider{*width:100%;height:1px;margin:9px 1px;*margin:-5px 0 5px;overflow:hidden;background-color:#e5e5e5;border-bottom:1px solid #ffffff;}
.dropdown-menu>li>a{display:block;padding:3px 20px;clear:both;font-weight:normal;line-height:20px;color:#333333;white-space:nowrap;}
.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus,.dropdown-submenu:hover>a,.dropdown-submenu:focus>a{text-decoration:none;color:#ffffff;background-color:#bf2e23;background-image:-moz-linear-gradient(top, #c83025, #b32b21);background-image:-webkit-gradient(linear, 0 0, 0 100%, from(#c83025), to(#b32b21));background-image:-webkit-linear-gradient(top, #c83025, #b32b21);background-image:-o-linear-gradient(top, #c83025, #b32b21);background-image:linear-gradient(to bottom, #c83025, #b32b21);background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc83025', endColorstr='#ffb32b21', GradientType=0);}
.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus{color:#ffffff;text-decoration:none;outline:0;background-color:#bf2e23;background-image:-moz-linear-gradient(top, #c83025, #b32b21);background-image:-webkit-gradient(linear, 0 0, 0 100%, from(#c83025), to(#b32b21));background-image:-webkit-linear-gradient(top, #c83025, #b32b21);background-image:-o-linear-gradient(top, #c83025, #b32b21);background-image:linear-gradient(to bottom, #c83025, #b32b21);background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc83025', endColorstr='#ffb32b21', GradientType=0);}
.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>a:hover,.dropdown-menu>.disabled>a:focus{color:#999999;}
.dropdown-menu>.disabled>a:hover,.dropdown-menu>.disabled>a:focus{text-decoration:none;background-color:transparent;background-image:none;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false);cursor:default;}

.btn{display:inline-block;padding:5px 12px;margin-bottom:0;font-size:12px;line-height:11px;text-align:center;vertical-align:middle;cursor:pointer;color:#FFFFFF;height:12px;} /* background-color:#0aadff; */
.btn-group:hover a,.btn:hover,.btn:active,.btn.active,.btn.disabled,.btn[disabled]{background-color:#0aadff;}

.btn-warning.active,.btn-danger.active,.btn-success.active,.btn-info.active,.btn-inverse.active{color:rgba(255, 255, 255, 0.75);}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active,.btn-primary.active,.btn-primary.disabled,.btn-primary[disabled]{background-color:#0aadff;}

#vip_docsDiv, #diy_docsDiv {
    margin: 10px 0px;
}

.statusSection {
    margin: 20px 0px 20px 20px;
}

.propertiesNavOpen{
    position: absolute;
    left: -1000px;
}

#wisp_guardian.active {
    left: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    -webkit-transition: all ease-out 100ms;
    -moz-transition: all ease-out 100ms;
    transition: all ease-out 100ms;
}

.wisp_m_icon {
    position: absolute;
    top: 13px;
    left: 20px;
    z-index: 10;
    font-size: 24px;
    opacity: .15;
}

.settingsInput {
    width: 350px;
}

.wt_textareadiv {
    width: 350px;
}

.authorFee {
    font-size: 12px;
    margin-left: 25px;
    margin-top: 3px;
}

.wispFee {
    font-size: 12px;
    margin-left: 15px;
    margin-top: 3px;
}

.settingsPrice {
    font-size: 12px;
    width: 95px;
    margin-right: 10px;
    margin-top: 3px;
}

.wispFeeAmount {
    margin: 1px 0px 0px 4px;
    padding: 0px;
}

.authorFeeSlider {
    margin: 5px 0px 0px 20px;
    padding: 0px;
}

.doc_column {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 10px;
}

.status_column {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    width: 75px;
    cursor:pointer;
}

.noContentDiv {
    font-size: 14px;
    margin: 100px 20px 0px 300px;
}

@media (max-width: 1300px) {
	.book_icon{width:200px;}
}

@media (max-width: 1300px) {
    .wisp_m_icon {
        top: 3px;
        left: 9px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {

    .noContentDiv {
        margin: 80px 20px;
    }

    .authorFeeSlider {
        margin-left: 0px;
    }

    .wispFeeAmount {
        margin-right: 100px;
    }

    .settingsPrice {
        margin: 20px 0px;
    }

    .priceInfo {
        margin-right: 50px;
    }
    .wispFee {
        margin: 20px 0px;
        width: 95px;
    }

    .authorFee {
        margin: 20px 0px;
        width: 95px;
    }

    .wt_textareadiv {
        width: 200px;
    }
    .settingsInput {
        width: 200px;
    }

    .newMesInputDiv {
        width: 320px;
    }

    .supportTextArea {
        width: 280px;
    }

    #supportTopics {
        margin: 10px 0px 0px 0px;
    }


    #webt_ui_support2 h2 {
        margin: 0px 0px 20px 0px;
    }

    #webt_ui_support2 {
        width: 100%;
        margin: 50px 0px 0px;
    }

    .acct_panel {
        width: 100%
    }

    .headerLogoImg {
        margin: 15px 0px 0px 30px;
    }

    span.logo {
        left: 18px;
        top: 5px;
        font-size: 17px;
    }

    .oneormore {
        width: 320px;
    }

    #header {
        height: 50px;
    }

    #nav_positioning {
        display: block;
    }

    .nav_list.active {
        top: 0px;
        -webkit-transition: all ease-out 200ms;
        -moz-transition: all ease-out 200ms;
        transition: all ease-out 200ms;
    }

    .fullscreen_popup .close_positioning {
        right: 10px;
        top: 5px;
    }

    .fullscreen_popup {
        margin: -40px 0 0 -20px;
        width: 100%;
        padding: 0;
    }

        .fullscreen_popup.active {
            margin: 0px 0 0 0px;
        }

    .popWispBox2 {
        margin-right: 0px;
    }

    .wisplist_spacing {
        padding: 0px;
    }

    .popHeading {
        font-size: 32px;
        padding-left: 15px;
    }

    .popWispBox {
        margin: 10px;
    }

    #pricingtable {
        margin-left: -20px;
    }

    #wispDetails {
        margin-left: -10px;
    }

    .scooch_13 {
        margin-left: 0px
    }

    .scooch_row2 {
        margin-left: 0px;
    }

    .box_row2 {
        width: 100%;
    }

    #wrapper {
        padding: 0px;
    }

    .spaced_top_foot {
        margin-top: -69px;
    }

    #wisplist_div {
        display: none;
    }

    #wisp_bubble {
        display: none;
    }

    .home_img_w {
        margin-left: 25px;
    }

    .home_img_x {
        width: 300px;
        margin: 10px 0px 0px 10px;
    }

    .scooch_half {
        margin: 0px
    }

    .scooch_quart {
        margin-left: 0px;
    }

    .wt_footer {
        overflow-x: hidden;
        padding: 120px 0px 0px
    }

        .wt_footer:before {
            top: 57px;
            left: -200px;
            z-index: 0;
            width: 700px;
            height: 700px;
        }

    .life_div {
        padding: 0px 0px 30px;
    }

        .life_div .sub_text {
            width: 280px;
        }

    .homeWispCont:after {
        left: -6px;
        top: -4px;
    }

    .lifeItem {
        font-size: 12px;
    }

    .banner_h4 {
        width: 82%;
    }

    #offerBut {
        width: 280px;
        font-size: 14px;
        left: 0px;
        top: -10px;
        line-height: 120%;
    }

    #offerBut {
        height: 100px;
    }

    .offer_head {
        font-size: 16px;
        line-height: 130%
    }

    .offer_body {
        font-size: 13px;
        margin-top: 5px;
    }

    .wisp_circle2 {
        width: 180px;
        height: 180px;
    }

        .wisp_circle2:after {
            top: -9px;
            left: 90px;
            width: 20px;
            height: 20px;
        }

    .circ2_people {
        top: -20px;
        left: 130px;
        font-size: 34px;
    }

    .circ2_excel {
        top: 163px;
        left: 135px;
        width: 45px;
        height: 59px;
    }

    .circ2_excelx {
        top: 10px;
        left: 16px;
        font-size: 24px;
    }

    #circ2_rows {
        margin: 42px 10% 0px;
    }

    .circ2_row {
        width: 99%;
        height: 8px;
        margin: -1px auto 0px;
    }

    #footer_box2 {
        padding: 0px 0px 0px 65px
    }

    #wisp_img_box {
        margin-top: 150px;
        width: 320px;
    }

    .lifeItem {
        margin: 0px;
    }

    .life_col1 {
        width: 50%;
    }

    .life_div .sub_text {
        font-size: 13px;
    }

    .banner h4, .banner_pric h4 {
        width: 90%;
        margin: 30px auto 65px;
    }

    .home_img_w {
        width: 320px;
    }

    .banner h1 {
        width: 244px;
    }

    .banner_item, .banner_item_w {
        width: 100%;
        margin-bottom: 15px;
    }

    .feature_pan_cont {
        padding: 0px;
        margin-top: 30px;
    }

    .feature_pan_div {
        width: 100%;
        padding: 0px;
    }

    .smart_bub_body {
        width: 305px;
    }

    .feature_half_pan {
        min-height: 0px;
    }

    .feature_pan {
        margin-bottom: 0px;
        margin-top: 30px;
    }

    .inner_banner {
        width: 280px;
    }

    .wisp_left, .wisp_right {
        font-size: 22px;
        display: block;
    }

    .wisp_left {
        left: -11px;
        top: 48px;
    }

    .wisp_right {
        top: 45px;
        right: -10px;
    }

    .wisp_div {
        padding: 15px 2px 5px 2px;
        margin-left: 0px;
    }

    .wisp_span {
        font-size: 9px;
    }

    .wisp_lineup, {
        margin-top: 70px;
        font-size: 26px;
        margin-bottom: 20px;
    }

    .inner_prev_div, .inner_prev_div_w {
        width: 320px;
        margin-left: 0px;
        padding: 20px
    }

    #wisp_desc, #wisp_desc_w {
        width: 280px;
    }

    .wisp_img {
        width: 280px;
    }

    .homeWispH1Span {
        font-size: 29px;
    }

    .wisp_lineup_sub {
        font-size: 13px;
        width: 300px;
        margin: 0px 10px;
    }

    .banner_items, .banner_items_w {
        max-width: 205px;
    }

        .banner_items > div, .banner_items_w > div {
            width: 75px;
            font-size: 11px;
        }

    .banner_item {
        width: 70px;
        height: 70px;
    }

    .banner_item_t {
        line-height: 69px;
    }

    #startBut {
        width: 275px;
    }

    #startBut1 {
        width: 275px;
    }

    .home_wispArr_w {
        display: none;
    }

    .banner2_ipanel {
        margin: 50px 20px 50px 20px;
    }

    .wisp_works_div {
        width: inherit;
    }

    .homeWispH1Div {
        width: inherit;
    }

    .homeWispH1 {
        font-size: 36px;
    }

    .wisp_guardian {
        position: absolute;
        z-index: 11001;     /* lower than header */
        width: 30px;
        height: 30px;
        top: 10px;
        margin: 0px 0 0 0px;
        border-radius: 50px;
        cursor: pointer;
        background: rgba(173,238,255,.8); /*rgba(4, 171, 255, .95);*/
        -webkit-transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
        -moz-transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
        transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
    }

    .wisp_guardian.open {
        left: 205px;
    }

    .wisp_guardian.closed {
        left: 7px;
    }



}

@-webkit-keyframes pulsatemove {
    0% {
        background: rgba(4, 171, 255, 1);
        left: 10px;
        top: 5px;
    }

    12% {
        background: rgba(4, 171, 255, .9);
        left: 15px;
        top: -10px;
    }

    25% {
        background: rgba(4, 171, 255, .8);
        left: 20px;
        top: 5px;
    }

    37% {
        background: rgba(4, 171, 255, .7);
        left: 25px;
        top: -10px;
    }

    50% {
        background: rgba(4, 171, 255, .6);
        left: 30px;
        top: 5px;
    }

    62% {
        background: rgba(4, 171, 255, .7);
        left: 25px;
        top: -10px;
    }

    75% {
        background: rgba(4, 171, 255, .8);
        left: 20px;
        top: 5px;
    }

    87% {
        background: rgba(4, 171, 255, .9);
        left: 15px;
        top: -10px;
    }

    100% {
        background: rgba(4, 171, 255, 1);
        left: 10px;
        top: 5px;
    }
}