/* EASY.CSS */

body {
    font-family: "Roboto",Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    word-spacing: 0;
    text-transform: none;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
}


.primaryColor {
    color: rgb(23, 31, 110);
}

.primaryButton {
    background: rgb(23, 31, 110);
    color: white;
    font-size: 16px;
    font-weight: 500;
}
.primaryButton:hover{
    background: rgb(23,31,156);        /* rgb(177, 204, 24); */
    color: white;
}

.secondaryButton {
    background: rgb(252,252,252);
    color: rgb(80,80,80);
    font-size: 16px;
    font-weight: 500;
}
.secondaryButton:hover {
    background: white;
    color: rgb(80,80,80);
}

.radioAccountButtonSel, .radioAccountSel {
    border-bottom: 2px solid rgb(23, 31, 110);
}

.wt_input{
	box-shadow: 0 0 1px rgba(0, 0, 0, .3);
}

.wt_input:focus, textArea:focus {
	outline: none;
	box-shadow: 0 0 4px rgba(0, 0, 0, .1), 0 0 0 1px rgba(45,120,205, 1);
}

.header {
    background: rgb(1, 8, 67);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
    z-index: 11002; /* higher than stuff in documents */
    color: rgb(255,255,255);
    font-size: 11px;
    text-transform: uppercase;
    font-family: sweet-sans-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
}

.banner {
	position: relative;
	padding: 100px 0 50px;
	margin: 0 0 0px;
	background: rgb(255, 255, 255);
	color:rgb(45,120,205);	
	text-align:center;
}

.titleClass {
    color: #FFFFFF;
    font-size: 18px;
    margin-top: 17px;
    margin-left: 270px;
    float: left;
}

.crazyPanel1 {
    background: rgb(247, 247, 247);
	position: relative;
}

.notificationError {
    color: #FFF;
    background: #a03c23;
}
.notificationSuccess {
    color: #FFF;
    background: #0daa0d;
}
.notificationWarning {
    color: #333;
    background:rgb(255,229,86);
}

.btn-group:hover a,.btn:hover,.btn:active,.btn.active,.btn.disabled,.btn[disabled]{background-color:rgb(23, 31, 110);}

#home {
    padding-top: 0;
}

#home canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

#landing {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
#landing .row {
    z-index: 10;
}
#landing p, #landing h1 {
    color: white;
}


.marketing_section {
    padding: 9vw 0;
}

.marketing_section h2 {
    font-weight: bold;
}





/* Base Settings */

:root {
	--color_1: rgba(255, 255, 255, 1);		/* White */
	--color_1_off: rgba(235, 235, 235, 1);	/* Off White */
	--color_2: rgba(0, 0, 0, 1);			/* Black */
	--color_3: rgba(2, 5, 70, 1);			/* Blue */
	--color_4: rgba(255, 130, 130, 1);		/* Pink */

	--main-padding: 20px;
	--main-padding-half: 10px 20px;
    --main-padding-double: 40px;
    --main-padding-neg: -20px;
    --main-padding-half-neg: -10px;
}


/*   G R I D   */

section, footer {
	margin: 0;
	padding: 0 var(--main-padding);
	float: left;
    width: 100%;
    box-sizing: border-box;
}

.box_1, .box_2, .box_2_5, .box_3, .box_4, .box_5, .box_6, .box_7, .box_8, .box_9 {
	float: left;
	box-sizing: border-box;
	position: relative;
	width: 100%;
	/*padding: 5px var(--main-padding-half);*/
}
.box_padded {
	padding: var(--main-padding-half);
}

.box_padded_special {
    padding: 30px;
}


.row {
	margin: 0 var(--main-padding-half-neg);
    clear: both;
}
.row.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.row:after, footer:after, footer li:after {
	content: '';
	display: block;
	clear: both;
}

.aspect_1xgolden:before, .aspect_1x1:before, .aspect_2x1:before, .aspect_2x3:before, .aspect_3x2:before, .aspect_3x4:before , .aspect_4x3:before {
	content: '';
	display: block;
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 61.8%;
}
.aspect_1x1:before {
	padding-bottom: 100%;
}
.aspect_2x1:before {
	padding-bottom: 50%;
}
.aspect_3x4:before {
	padding-bottom: 133.333333%;
}
.aspect_4x3:before {
	padding-bottom: 75%;
}
.aspect_2x3:before {
	padding-bottom: 150%;
}
.aspect_3x2:before {
	padding-bottom: 66.666666%;
}
.not_mobile {
	display: none;
}

@media (min-width: 1000px) {

	.not_mobile {
		display: block;
	}

	.box_1, .box_2, .box_2_5, .box_3, .box_4, .box_5, .box_6, .box_7, .box_8, .box_9, .box_10, .box_11, .box_12 {
		padding: var(--main-padding-half) var(--main-padding-half) var(--main-padding-half-neg);
	}
	.box_1 {
		width: 11.111111%;
	}
	.box_2 {
		width: 22.222222%;
	}
    .box_2_5 {
        width: 25%;
    }
	.box_3 {
		width: 33.333333%;
	}
	.box_4 {
		width: 44.444444%;
	}
	.box_5 {
		width: 55.555555%;
	}
	.box_6 {
		width: 66.666666%;
	}
	.box_7 {
		width: 77.777777%;
	}
	.box_8 {
		width: 88.888888%;
	}
	.box_9 {
		width: 100%;
	}
    .scooch_half {
		margin-left: 8.5%;
    }
	.scooch_1 {
		margin-left: 11.111111%;
	}
	.scooch_2 {
		margin-left: 22.222222%;
	}
	.scooch_3 {
		margin-left: 33.333333%;
	}
	.scooch_4 {
		margin-left: 44.444444%;
	}
	.scooch_5 {
		margin-left: 55.555555%;
	}
	.box_padded {
		padding: var(--main-padding-half);
	}
    .box_padded_special {
        padding: 30px;
    }
        .md_aspect_1x1:before {
		padding-bottom: 100%;
	}
	.md_aspect_2x1:before {
		padding-bottom: 50%;
	}
	.md_aspect_1xgolden:before {
		padding-bottom: 61.8%;
	}
	.md_aspect_3x2:before {
		padding-bottom: 66.666666%;
	}
	.md_aspect_3x4:before {
		padding-bottom: 133.333333%;
	}

}


/*   C O L O R   */


/*   Background   */
html, body, header, header nav {
    background: var(--color_1);
}
@media (min-width: 1000px) {
	html, body, header, header nav, {
	    background: var(--color_1);
	}
}
.section_gray {
    background: var(--color_1_off);
}

header.homescroll {
	background: none;
}
header.homescroll p, header.homescroll a, footer a, footer p, footer li {
	color: var(--color_1);
}










/*   T Y P O G R A P H Y   */

@font-face {
  	font-family: "Source Code Pro";
  	src: url("../fonts/SourceCodePro-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
  	font-family: "Source Code Pro";
  	src: url("../fonts/SourceCodePro-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}
@font-face {
  	font-family: "Source Sans Pro";
  	src: url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
  	font-family: "Source Sans Pro";
  	src: url("../fonts/SourceSansPro-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

/* h1, h2, h3, h4, p, a {
	margin: 0;
	padding: 0;
} */

body, section#footer li, footer li {
    font-family: 'Source Sans Pro', sans-serif;
	font-style: normal;
    font-weight: 400;
}

.logoClass {
    margin: 16px 0 0 30px;
    width: 30px;
    cursor: pointer;
}

.logo, button.primary, footer li.title {
	font-family: 'Source Code Pro', monospace;
	font-style: normal;
	font-weight: 600;
}
h1, h2.h1, header nav a p, h3, h4 {
    font-family: 'Source Sans Pro', sans-serif;
	font-style: normal;
	font-weight: 700;
}

button.primary {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: .1em;

	position: relative;
	text-align: left;
	min-width: 240px;
	background: var(--color_1);
	color: black;
	height: 50px;
	padding: 13px 20px;
	margin-top: 10px;
	border: none;
	outline: none;
	border-radius: 0px;
	cursor: pointer;

    -webkit-transition: all ease-out 200ms;
       -moz-transition: all ease-out 200ms;
            transition: all ease-out 200ms;
}
button.primary:hover {
	background: var(--color_1_off);
}
button.primary:after {
	content: '\2192';
	font-family: 'Source Code Pro', monospace;
	font-style: normal;
	text-transform: none;
    font-weight: 500;
    font-size: 21px;
    line-height: 20px;
    position: absolute;
    right: 16px;
    top: 13px;
    width: 15px;
    height: 20px;

    -webkit-transition: all ease-out 200ms;
       -moz-transition: all ease-out 200ms;
            transition: all ease-out 200ms;
}
button.primary:hover:after {
	right: 11px;
}

footer li.title {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: .1em;
}

@media (min-width: 760px) {

	header nav a p {
		font-family: 'Source Code Pro', monospace;
		font-style: normal;
	    font-weight: 600;
	}

}

#home p, #home h1, #home h2, #home h3, #home h4 {
    margin: 0;
    padding: 0;
}
#home p {
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}
#home p {
    padding: 0 0 10px;
    max-width: 600px;
}
#home h1, #home h2.h1 {
    font-size: 43px;
    line-height: 40px;
    text-transform: none;
    margin-left: -2px;
    letter-spacing: -.01em;
    margin-bottom: .25em;
}
#home h2, #home h3 {
    font-size: 26px;
    line-height: 25px;
    margin-left: -1px;
    margin-bottom: .25em;
}
#home h4 {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: .07em;
}
#home header nav a p {
    font-size: 55px;
    line-height: 50px;
    margin: 0 0 10px -1px;

    letter-spacing: -.02em;
}

#home li {
    font-size: 15px;
    line-height: 20px;
    font-style: italic;
}
#home a {
    text-decoration: none;
    cursor: pointer;
    margin: 0;
}
#home a p {
    display: inline-block;
    padding: 0;
}
#home a:hover p {
    box-shadow: 0 1px 0 0 black;
}

header.homescroll a:hover p {
    box-shadow: 0 1px 0 0 var(--color_1);
}

.logoText {
	font-size: 13px;
	position: relative;
    letter-spacing: .1em;
    float: left;
    margin: 6px 0px 0px 12px;
    text-transform: none;
}

@media (min-width: 760px) {

	header nav a p {
	    font-size: 13px;
	    line-height: 20px;
	    text-transform: uppercase;
	    letter-spacing: .1em;
    }

}

@media (min-width: 1000px) {
    #home h1, #home h2.h1 {
        font-size: 43px;
        line-height: 40px;
        letter-spacing: -.01em;
        margin-left: -3px;
    }
}

@media (min-width: 1600px) {
    #home h1, h2.h1 {
        font-size: 43px;
        line-height: 40px;
        letter-spacing: -.01em;
        margin-left: -2px;
    }
    #home h3 {
        font-size: 26px;
        line-height: 25px;
        margin-left: -2px;
    }
}














/*   H E A D E R   */

header {
    display: block;
    box-sizing: border-box;
	position: fixed;
	z-index: 1000;
    top: 0;
    left: 0;
    padding: 0 var(--main-padding);
    width: 100%;
    height: 60px;
}
header a.logo {
    padding: 10px;
	z-index: 1000;
    display: block;
    position: relative;
    float: left;
	margin: 8px 0 0 -11px;
    -webkit-font-smoothing: antialiased;
}
header a.logo:hover p {
    box-shadow: none !important;
}
header svg {
	float: left;
	width: 30px;
	height: 30px;
    margin: -4px 10px 0 0;
}
header.homescroll svg {
	fill: var(--color_1);
}
header nav {
    position: absolute;
    top: -100vh;
    left: 0px;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    padding: 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
header.active nav {
    top: 0vh;
    left: 0px;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    padding: 0 0 var(--main-padding-double);
    box-sizing: border-box;
}
header nav a {
    float: none;
	position: relative;
	display: block;
	padding: 5px var(--main-padding);
    -webkit-font-smoothing: antialiased;
}
header nav a p {
    margin: 0;
    padding: 0;
}
header nav a.active {
	cursor: default;
}
header nav a.active:after {
	content: '';
	display: block;
	width: calc(100% - 30px);
    height: 2px;
    position: absolute;
    bottom: 20px;
    left: 15px;
}
button#nav_toggle {
    top: 0px;
    right: 5px;
    border: none;
    width: 60px;
    height: 60px;
    padding: 15px;
    z-index: 1000;
    cursor: pointer;
    display: block;
    background: none;
    position: absolute;
}
button#nav_toggle:focus {
    outline: none;
}
button#nav_toggle span {
    width: 100%;
    height: 3px;
    margin: 5px 0 9px;
    display: block;
    position: relative;

	    -webkit-transition: all ease-out .1s;
	       -moz-transition: all ease-out .1s;
	            transition: all ease-out .1s;
}

button#nav_toggle.active span {
    opacity: 0;
}
button#nav_toggle.active span.one {
    transform: rotate(45deg);
    opacity: 1;
    margin: -2px 0 -3px;
}
button#nav_toggle.active span.two {
    transform: rotate(-45deg);
    opacity: 1;
    margin: 0 0 0;
}

@media (min-width: 760px) {

	header {
	    padding: 0 20px;
	    height: 74px;
	}
	header nav, header.active nav {
	    position: absolute;
	    margin: 0 -1px 0 0;
	    right: var(--main-padding-half);
	    left: auto;
	    top: 8px;
	    width: auto;
	    height: auto;
	    padding: 0;
	    background: none;
	    display: block;
	}
	header nav a {
	    float: left;
		padding: 17px var(--main-padding);
	}
	header a.logo {
		margin: 14px 0 0 -11px;
	}
    button#nav_toggle {
        display: none;
    }

}

.crazyCont {
	padding: 100px 0 50px 0px;
    margin: 0 auto;
    display: flex;
    max-width: 800px;
}

.crazyPanel2 {
    background: rgb(46,46,46);
}

.crazyTitle {
    font-size: 32px;
}

.crazySubTitle {
    font-size: 13px;
    font-style: italic;
    margin-top: 3px;
    width: 300px;
}

.crazyPanel1a {
    width: 40%;
    margin-top: 40px;
}
  
.crazyPanel1b {
    width: 60%;    
}

.crazyPanel1bCont {
    display: flex;
}

.crazyPanel1bCol1 {
    width: 50%;
}

.crazyPanel1bCol2 {
    width: 50%;
    margin-top: 100px;
}

.col1Container{
    float: right;
    margin: 50px 40px 0px 0px;
}

.header_list {
	margin: 0 0px 0px 10px;
	list-style-type: none;
    position: relative;
    z-index: 5;
	float: right;
    box-sizing: border-box;
}

.searchIcon {
    font-size: 18px;
    float: right;
    cursor: pointer;
    margin-right: 35px;
    padding: 11px 20px;
}

.searchIcon:hover {
    font-size: 20px;
}

.header_list a li:hover, .header_list li:hover {
    font-weight: 600;
	
  	-webkit-transition: all ease-out 150ms;
       -moz-transition: all ease-out 150ms;
     		transition: all ease-out 150ms;
}

.headerLi {
    float: right;
    cursor: pointer;
    font-size: 16px;
    margin: 9px 35px 0px 0px;
    padding: 10px 20px;
    text-transform: uppercase;
    display:inline;
}

.searchIcon {
    margin: 7px 20px 0px;
}

.footer{background:rgb(247,247,247);}
.footerCont{padding:60px 0px;max-width: 800px; margin: 0px auto;}

.homeTileCont {
    width: 1300px;
    margin: 0 auto;
    display: flex;
}
.crazyTile {
    position:relative;
    width: 300px;
    margin: 0px 10px;
}

.bottomPanel {
    background: rgb(244,244,244);
    height: 190px;
    width: 300px;
    margin: 0px auto;
}

.innerBottomPanel {
    width: 100%;
    height: 100%;
    background: white;
    overflow: hidden;
}

.dollar_icon{
    font-size:100px;
    color:rgb(255,255,255);
}

.eventSectionTitle{
    font-size: 24px;
    color: rgb(247,197,68);
    margin-bottom: 10px;
}

.eventTextPanel {
    background:rgb(244, 244, 244);       /* 177, 204, 24 */
    padding:20px 20px 10px;
    width: 100%;
    height: 240px;
    color: rgb(45,120,205);
    text-align: left;
}

.eventAction {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    height: 80px;
}
.eventSubAction {
    font-size: 15px;
    color: rgb(139, 139, 139);
}

.eventTileTitle {
    position: absolute;
    bottom: 90px;
    left: 15px;
    font-size: 36px;
    color: #FFF;
    font-weight: 600;
}

.eventTileSubTitle {
    position: absolute;
    bottom: 74px;
    left: 15px;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
}

.tengine_button {
    text-align:center;
    cursor:pointer;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 10px 30px; /* need padding on right so room for spinner */
    font-family: Arial;
    position: relative; /* needed for spinner */
}

.homeWispH1{
    font-style: normal;
    font-size: 54px;
    text-align:center;
    margin: 80px auto 0px;
    max-width: 500px;
}

.homeWispH4{
    margin: 60px auto 0px;
    font-size: 21px;
    font-weight: 400;
    font-style:normal;
    line-height: 30px;
    padding: 0px 10px;
    text-align: center;
}

.story_icon_div {
    font-size:100px;
    color:rgb(45,120,205);         /* 110, 160, 65 */
    text-align: center;

}

.startBufCont{vertical-align:top;padding: 20px;width: 340px; margin: 90px auto 0px;}

.homeActionDiv {
    margin-top: 30px;
}

#get_code_div {
    background: #FFF;
    padding: 30px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 160px;
    width: 660px;
    z-index: 110003; /* greater than fullscreen_popup_sub */
    -webkit-overflow-scrolling: touch;
    -webkit-transition: all ease-out 290ms;
    -moz-transition: all ease-out 290ms;
    transition: all ease-out 290ms;
    border: 1px solid #EEE;
    max-height: 500px;
    overflow-y: scroll;
}

.footer_column {
    font-size: 13px;
    color: rgb(45,120,205);
    width: 170px;
}

.footer_column3 {
    font-size: 13px;
    color: #666;
    width: 460px;
}

.headerMenuDiv {
    color: rgb(255,255,255);
    position: relative;
}

.nav_panel_fixed{
    position:fixed;
    background:rgb(247, 247, 247);
    height:100%;
    width:250px;
    color:rgb(45,120,205);
    z-index: 10;
    top:60px;
    left: 0px;
    padding-left:10px;
}

.bookSettingsContainer {
    padding: 100px 25px 30px 420px;
    max-width: 1200px;
}

.pageBody {
    width: 100%;
    height: 100%;
    background:rgb(247, 247, 247);
}

.main_pan_item.active, .main_pan_fitem.active{  
    background: rgb(240,240,240);
}
.main_pan_item:hover,.main_pan_fitem:hover{ 
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, .25);
}

/* .closeClass {
} */

.close_pos_float_50{        /* inside div with padding 50 */
	float:right;
    margin: -40px -40px -6px 0px;
}

.close_pos_float_30{        /* inside div with padding 30 */
	float:right;
    margin: -20px -20px -6px 0px;
}

.close_pos_float_20{        /* inside div with padding 20 */
	float:right;
    margin: -10px -10px -6px 0px;
}

.panel1Cont {
    width: 100%;
    height: 600px;
    display: flex;
    margin-top: 60px;
}

.panel1aCont {
    background-image: url('../images/easy_background.jpg');
    background-size: cover;
    width: 60%;
    height: 100%;
}

.panel1bCont {
    width: 40%;
    height: 100%;
    background: rgb(255,255,255);
    color: rgb(45,45,45);
    padding: 20px;
}

.feature_pan_title{
    font-size:40px;
	text-align:center;
    font-style: normal;
    font-weight: 500;
    color: #333;
    text-transform:uppercase;
}

.worksContainer {
    max-width: 800px;
    margin: 70px auto 110px;
    padding: 50px 50px;
}

.worksPanel {
    padding:20px 20px 10px;
    height: 150px;
    color: white;
    text-align: left;
    display: flex;
    margin-bottom: 20px;
}

.worksPanelCont {
    display: flex;
    background: rgb(247,247,247);
    margin-bottom: 20px;
}

.worksNumber1 {
    background: rgb(45,120,205);
}

.worksNumber2 {
    background: rgb(128, 128, 128);
}

.worksNumber3 {
    background: rgb(177, 204, 24);
}

.worksNumber {
    font-size: 64px;
    padding: 20px 20px;
}

.worksDesc {
    font-size: 23px;
    margin-left: 35px;
    color: rgb(96,96,96);
    width: 300px;
}

.worksDesc1 {
    margin-top: 15px;
}

.worksDesc2 {
    margin-top: 30px;
}

.worksDesc3 {
    margin-top: 40px;
}

.works_icon_div{
    font-size:70px;
    color:rgb(45,120,205);         /* 110, 160, 65 */
    width: 225px;
    text-align: center;
}

.works_icon {
    margin-top:40px;
    margin-left: 100px;
}

.works_icon2 {
    margin-top:40px;
    margin-left: 100px;
    color: rgb(128, 128, 128);         /* 110, 160, 65 */
}

.works_icon3 {
    margin-top:40px;
    margin-left: 100px;
    color:rgb(177, 204, 24);         /* 110, 160, 65 */
}

.trustPanel {
    background: rgb(128,128,128);
    width: 100%;
    height: 80px;
}

.trustImageDiv {
    width: 250px;
    margin: 0px auto 0px;
}

.trustImage {
    margin-top: -20px;
}

.trustTextDiv {
    float: left;
    margin: 29px 0px 0px -100px;
    color: white;
    font-size: 16px;
}

.wisp_works_hint{
    margin: 0px auto 0px;
    padding: 80px 50px 70px;
    background: rgb(177, 204, 24);
    color: #FFF;
    text-align: center;
}

.works_cont {
    width: 700px;
    margin: 0px auto;
}

.hintTitle {
    font-size: 36px;
}

.hintSubTitle {
    font-size: 18px;
    margin-top: 20px;
}

.wisp_works_hint a {
    color: #FFF;
}

#teamname_div,#password_div,#teamurl_div,#email_div,#email_j_div,#password_j_div,#referral_div,#last_name_div,#first_name_div{margin-top:35px;width:300px;}

.wispPageContainer {
    margin: 60px 0px 0px 100px;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.wispPageContainer.float {
    position: absolute;
    left: 200px;
}

.panel_title {
    text-transform: uppercase;
    font-size: 30px;
    font-family: "Surveyor SSm A", "Surveyor SSm B","Open Sans", Helvetica;
    font-weight: 600;
    margin-bottom: 30px;
}

.faqDocDiv {
    min-width: 1200px;
    min-height: 800px;
    padding-top: 50px;
}

.homeArrowDiv {
    text-align:center;
    margin-top: 20px;
    font-size: 30px;
}
@media (max-width: 480px) {
    .box_vert_margin {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    section, footer {
        padding: 0px;
    }

    #get_code_div {
        width: 100%;
        box-sizing: border-box;
        max-height: 300px;
    }

    .panel_title {
        margin-left: 25px;
    }

    .row.flex {
        display: block;
    }
    .marketing_section {
        padding: 20px 0px;
    }
    
    .faqDocDiv {
        min-width: 375px;
        min-height: 600px;
    }

    .logoClass {
        margin: 12px 0 0 20px;
        width: 35px;
    }
    .logoText {
        margin-top: 5px;
    }
    .titleClass{
        display: none;
    }

    .headerLi {
        margin: 9px 0px 0px;
        padding: 8px 20px;
    }

    .searchIcon {
        margin: 9px 5px 0px 0px;
    }

    .panel1Cont {
        display: block;
    }

    .panel1aCont {
        width: 100%;
        height: 200px;
    }

    .panel1bCont {
        width: 100%;
    }

    .homeWispH1 {
        margin: 30px 0px;
        font-size: 36px;
    }

    .homeWispH4 {
        margin: 30px 10px;
        font-size: 14px;
        line-height: 24px;
    }

    .startBufCont {
        margin: 50px 0px;
    }

    .homeTileCont {
        display: block;
    }

    .homeTileCont {
        width: 100%;
    }

    .crazyTile {
        width: 100%;
        margin: 0px;
    }

    .banner {
        padding-top: 50px;
    }

    .bottomPanel {
        width: 100%;
    }

    .worksContainer {
        margin: 30px 0px;
        width: 100%;
        padding: 10px;
    }

    .worksPanel {
        height: 80px;
    }

    .worksNumber {
        font-size: 24px;
        padding: 10px;
    }

    .worksDesc {
        font-size: 14px;
        width: 150px;
    }

    .worksDesc1, .worksDesc2, .worksDesc3 {
        margin-top: 0px;
    }
    
    .works_icon_div {
        width: 80px;
        font-size: 48px;
    }

    .works_icon {
        margin: 23px 0px 0px 20px;
    }

    .works_icon2 {
        margin: 23px 0px 0px 20px;
    }    

    .works_icon3 {
        margin: 23px 0px 0px 20px;
    }

    .trustPanel {
        height: 50px;
    }

    .trustTextDiv {
        margin: 14px 0px 0px -80px;
        font-size: 14px;
    }

    .trustImageDiv {
        width: 150px;
    }
    .trustImage {
        width: 150px;
        margin-top: -16px;
    }

    .wisp_works_hint {
        width: 100%;
        margin: 0px;
        padding: 30px 0px;
    }

    .works_cont {
        width: 100%;
    }

    .hintTitle {
        font-size: 24px;
    }

    .hintSubTitle {
        font-size: 14px;
    }

    .nav_panel_fixed {
        top: 50px;
        width: 200px;
    }

    .bookSettingsContainer {
        padding: 100px 20px 30px 20px;
        max-width: none;
    }

    .footer_column {
        width: 100%;
    }

    .footer_column2 {
        margin-top: 25px;
    }

    .footer_column3 {
        width: 100%;
        margin-top: 25px;
    }

    .footerCont {
        margin: 0px 20px;
        padding: 20px 0px;
    }

    .fullscreen_popup_sub {
        width: 100%;
        left: 0px;
        transform: none;
        padding: 20px;
    }

    #teamname_div,#password_div,#teamurl_div,#email_div,#email_j_div,#password_j_div,#referral_div,#last_name_div,#first_name_div{margin-top:35px;width:100%;}

    .wispPageContainer {
        margin: 60px 0px 30px 0px;
        max-width: none;
        width: 100%;
    }


}

