/*     wisp-doc.css     */

.wispClassic .pageDivider {
    background: #EEE;
    height: 10px;
    width: 100%;
    margin: 5px 0px;
}

.wispBlog .pageDivider {
    height: 2px;
    margin: 10px 30px;    
}

.wispBlog .wispImage {
    object-fit: cover;
    max-height: 700px;
}

.wispBlog .docx_p_normal {
    font-size: 16px;
    font-family: Arial;
    line-height: 180%;
    color: #525252;
    margin-bottom: 10px;
}

.wispBlog docx_p_subtitle {
    font-size: 14px;
    font-family: Arial;
}

.wispBlog .docx_p_heading {
    font-size: 24px;
    font-family: Arial;
    margin: 10px 0px;
}

.wispBlog #pub_doc {
    padding: 30px;
}

.wispBlog .docx_embed_video {
    min-height: 600px;
}
