/*   webtotality.css */


 /*                     G R I D                       */

.box_1, .box_2, .box_3, .box_4, .box_5, .box_6, .box_7, .box_8, .box_9, .box_10, .box_11, .box_12 {
      position: relative;
      display: block;
      float: left;
      padding: 10px;
/*      z-index: 1;*/

      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
}

.box_reset {
      display: block;
      padding: 0;
      margin: -10px;
} /*
.box_reset:after, .box_1:after, .box_2:after, .box_3:after, .box_4:after, .box_5:after, .box_6:after, .box_7:after, .box_8:after, .box_9:after, .box_10:after, .box_11:after, .box_12:after {
      clear: both;
}
.box_reset:before, .box_1:before, .box_2:before, .box_3:before, .box_4:before, .box_5:before, .box_6:before, .box_7:before, .box_8:before, .box_9:before, .box_10:before, .box_11:before, .box_12:before {
      display: table;
      content: " ";
}
:before, :after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
}*/
/*

.box_1 {
      width: 8.33333333333%;
}
.box_2 {
      width: 16.66666666666%;
}
.box_3 {
      width: 25%;
}
.box_4 {
      width: 33.33333333333%;
}
.box_5 {
      width: 41.66666666666%;
}
.box_6 {
      width: 50%;
}
.box_7 {
      width: 58.33333333333%;
}
.box_8 {
  width: 66.66666666666%;
}
.box_9 {
  width: 75%;
}
.box_10 {
  width: 83.33333333333%;
}
.box_11 {
  width: 91.66666666666%;
}
.box_12 {
  width: 100%;
}




.scooch_1 {
  margin-left: 8.33333333333%;
}
.scooch_2 {
  margin-left: 16.66666666666%;
}
.scooch_3 {
  margin-left: 25%;
}
.scooch_4 {
  margin-left: 33.33333333333%;
}
.scooch_5 {
  margin-left: 41.66666666666%;
}
.scooch_6 {
  margin-left: 50%;
}
.scooch_7 {
  margin-left: 58.33333333333%;
}
.scooch_8 {
  margin-left: 66.66666666666%;
}
.scooch_9 {
  margin-left: 75%;
}
.scooch_10 {
  margin-left: 83.33333333333%;
}
.scooch_11 {
  margin-left: 91.66666666666%;
}
.scooch_12 {
  margin-left: 100%;
}



*/






.opacity-onethird {
      opacity: .33;
}

.opacity-twothirds {
      opacity: .66;
}

.opacity-full {
      opacity: 1;
}


/* h1, h2, h3, h4, h5, p, a {
      font-weight: normal;
      font-weight: 400;
      padding: 0;
      margin: 0;
}

ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
} */

a img {border:none;}

p.small, span.small {
      font-size: 11px;
}

#fontPicker li{padding-bottom:6px;}
#fontPicker li:hover{border:solid;border-width:3px;border-color:rgb(92,192,244);background:#FFF;}
#fontSzPicker li{padding:4px;}
#fontSzPicker li:hover{background-color:#EEE;}

.fontname {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  line-height: 16px;
  margin: 0px 0px -2px 0px;
}
.fontname .fontweights {
  font-weight: 400;
  font-style: italic;
  color: #999;
}

.fontlist {
  margin: 0px;
}

.fontlist li {
  list-style-type: none;
  padding: 7px 5px;
  margin: 0px 0px 0px -10px;
  cursor: pointer;
  border: 1px solid #FFF;

  -webkit-transition: all ease-in-out .15s;
     -moz-transition: all ease-in-out .15s; }

.fontlist li:hover {
  border: 1px solid #CCC;
}

.fontline {
  width: 100%;
  height: 25px;
  margin: 0px 0px 0px 15px;
}

#arial { background: url(../images/fonts.png) no-repeat; background-position: 0px 0px; } 
#arvo { background: url(../images/fonts.png) no-repeat; background-position: 0px -25px; } 
#comicsans { background: url(../images/fonts.png) no-repeat; background-position: 0px -50px; } 
#couriernew { background: url(../images/fonts.png) no-repeat; background-position: 0px -75px; } 
#dancingscript { background: url(../images/fonts.png) no-repeat; background-position: 0px -100px; } 
#droidsans { background: url(../images/fonts.png) no-repeat; background-position: 0px -125px; } 
#droidserif { background: url(../images/fonts.png) no-repeat; background-position: 0px -150px; } 
#georgia { background: url(../images/fonts.png) no-repeat; background-position: 0px -175px; } 
#thegirlnextdoor { background: url(../images/fonts.png) no-repeat; background-position: 0px -200px; } 
#helvetica { background: url(../images/fonts.png) no-repeat; background-position: 0px -225px; } 
#impact { background: url(../images/fonts.png) no-repeat; background-position: 0px -250px; } 
#lato { background: url(../images/fonts.png) no-repeat; background-position: 0px -825px; } 
#librebaskerville { background: url(../images/fonts.png) no-repeat; background-position: 0px -800px; } 
#lobster { background: url(../images/fonts.png) no-repeat; background-position: 0px -275px; } 
#lucidaconsole { background: url(../images/fonts.png) no-repeat; background-position: 0px -300px; } 
#lucidasansunicode { background: url(../images/fonts.png) no-repeat; background-position: 0px -325px; } 
#opensans { background: url(../images/fonts.png) no-repeat; background-position: 0px -350px; } 
#oswald { background: url(../images/fonts.png) no-repeat; background-position: 0px -375px; } 
#oxygen { background: url(../images/fonts.png) no-repeat; background-position: 0px -775px; } 
#pacifico { background: url(../images/fonts.png) no-repeat; background-position: 0px -400px; } 
#palatinolinotype { background: url(../images/fonts.png) no-repeat; background-position: 0px -425px; } 
#rokkitt { background: url(../images/fonts.png) no-repeat; background-position: 0px -450px; } 
#rocksalt { background: url(../images/fonts.png) no-repeat; background-position: 0px -475px; } 
#shadowsintolight { background: url(../images/fonts.png) no-repeat; background-position: 0px -500px; } 
#specialelite { background: url(../images/fonts.png) no-repeat; background-position: 0px -525px; } 
#tahoma { background: url(../images/fonts.png) no-repeat; background-position: 0px -550px; } 
#tangerine { background: url(../images/fonts.png) no-repeat; background-position: 0px -575px; } 
#timesnewroman { background: url(../images/fonts.png) no-repeat; background-position: 0px -600px; } 
#trebuchet { background: url(../images/fonts.png) no-repeat; background-position: 0px -625px; } 
#ubuntu { background: url(../images/fonts.png) no-repeat; background-position: 0px -650px; } 
#verdana { background: url(../images/fonts.png) no-repeat; background-position: 0px -675px; } 
#vollkorn { background: url(../images/fonts.png) no-repeat; background-position: 0px -700px; } 
#roboto { background: url(../images/fonts.png) no-repeat; background-position: 0px -725px; } 
#robotoslab { background: url(../images/fonts.png) no-repeat; background-position: 0px -750px; }

.tinyx {
  width: 10px;
  height: 10px;
  cursor: pointer;
}






/*@media (max-width: 1199px) {

  .box_1 {
        width: 10%;
  }
  .box_2 {
        width: 18%;
  }
  .box_3 {
        width: 28%;
  }
  .box_4 {
        width: 36%;
  }
  .box_5 {
        width: 44%;
  }
  .box_6 {
        width: 53%;
  }
  .box_7 {
        width: 60%;
  }
  .box_8 {
        width: 80%;
  }
  .box_9 {
        width: 82%;
  }
  .box_10 {
        width: 85%;
  }
  .box_11 {
        width: 93%;
  }
  .box_12 {
        width: 100%;
  }




  .scooch_1 {
    margin-left: 4%;
  }
  .scooch_2 {
    margin-left: 8%;
  }
  .scooch_3 {
    margin-left: 25%;
  }
  .scooch_4 {
    margin-left: 33.33333333333%;
  }
  .scooch_5 {
    margin-left: 41.66666666666%;
  }
  .scooch_6 {
    margin-left: 50%;
  }
  .scooch_7 {
    margin-left: 58.33333333333%;
  }
  .scooch_8 {
    margin-left: 66.66666666666%;
  }
  .scooch_9 {
    margin-left: 75%;
  }
  .scooch_10 {
    margin-left: 83.33333333333%;
  }
  .scooch_11 {
    margin-left: 91.66666666666%;
  }
  .scooch_12 {
    margin-left: 100%;
  }

}
*/




/*
@media (max-width: 979px) {

      .scooch_1, .scooch_2, .scooch_3, .scooch_4, .scooch_5, .scooch_6, .scooch_7, .scooch_8, .scooch_9, .scooch_10, .scooch_11, .scooch_12 {
            margin: 1%;
      }
      .box_1, .box_2, .box_3, .box_4, .box_5, .box_6, .box_7, .box_8, .box_9, .box_10, .box_11, .box_12 {
            padding: 10px 20px;
            width: 98%;
      }
      .box_1.mob_nopad, .box_2.mob_nopad, .box_3.mob_nopad, .box_4.mob_nopad, .box_5.mob_nopad, .box_6.mob_nopad, .box_7.mob_nopad, .box_8.mob_nopad, .box_9.mob_nopad, .box_10.mob_nopad, .box_11.mob_nopad, .box_12.mob_nopad {
            padding: 10px 0;
      }
      .box_reset {
            margin: -10px -20px;
      }

}

@media (max-width: 550px) {

      .scooch_1, .scooch_2, .scooch_3, .scooch_4, .scooch_5, .scooch_6, .scooch_7, .scooch_8, .scooch_9, .scooch_10, .scooch_11, .scooch_12 {
            margin: 0;
      }
      .box_1, .box_2, .box_3, .box_4, .box_5, .box_6, .box_7, .box_8, .box_9, .box_10, .box_11, .box_12 {
            padding: 10px 10px;
            width: 100%;
      }
      .box_1.mob_nopad, .box_2.mob_nopad, .box_3.mob_nopad, .box_4.mob_nopad, .box_5.mob_nopad, .box_6.mob_nopad, .box_7.mob_nopad, .box_8.mob_nopad, .box_9.mob_nopad, .box_10.mob_nopad, .box_11.mob_nopad, .box_12.mob_nopad {
            padding: 10px 0;
      }
      .box_reset {
            margin: -10px -20px;
      }

} */

#nav_toggle_cont{
    position: fixed;
    top: 100px;
    right: 20px;    
    width: 33px;
    height: 12px;
    z-index: 9000;
    cursor: pointer;
  }

  #links_editicon{
    position: fixed;
    top: 150px;
    right:0px;
    top: 350px;
    cursor: pointer;
  }


#nav_toggle {
  padding: 9px 10px 11px 10px;
  width: 13px;
  height: 13px;  
  cursor: pointer;
}
.shareTD{width:20px;height:20px;padding:6px;cursor:pointer;}
.shareTD2{display:none;}
#nav_toggle, #shareIconsDiv li, .shareTD, .themeToggle {
  box-shadow: 0 0 15px rgba(255, 255, 255, .1);
  border-radius: 50px;

  -webkit-transition: all ease-out 250ms;
     -moz-transition: all ease-out 250ms;
          transition: all ease-out 250ms;
}
#nav_toggle:hover, #shareIconsDiv li:hover, #nav_toggle.active, #shareIconsDiv li.active {
  box-shadow: 0 0 15px rgba(255, 255, 255, .2);
  border-radius: 50px;

  -webkit-transition: all ease-out 250ms;
     -moz-transition: all ease-out 250ms;
          transition: all ease-out 250ms;
}

#nav_toggle_cont.active {
  right: 280px;
}

.togline {
  display: block;
  height: 2px;
  width: 100%;
  margin: 2px 0px 3px;
  box-shadow: 0 0 5px 2px rgba(255, 255, 255, .2);

  -webkit-transition: all ease-out 250ms;
     -moz-transition: all ease-out 250ms;
}
#nav_toggle:hover .togline, #nav_toggle_cont.active .togline {
  background: #758996;
  background: rgba(117, 137, 150, 1);
}

#nav_menu_background {
  height: 100%;
  width: 260px;
  position: fixed;
  right: -260px;
  z-index: 110;
  background: #2b3034;
  -webkit-transition: right ease 250ms;
     -moz-transition: right ease 250ms;
          transition: right ease 250ms;
}

#nav_menu_background.active {
  right: 0px;
}

#nav_menu {
  height: 100%;
  width: 260px;
  position: fixed;
  right: 0;
  z-index: 110;
  /*padding: 20px 30px;*/

  -webkit-transition: all ease-out 250ms;
     -moz-transition: all ease-out 250ms;
          transition: all ease-out 250ms;
  right: -260px;
  text-align:center;
}
#nav_menu.active {
  right: 0px;
}
/*#nav_menu li:hover {
  color: rgba(255, 255, 255, .3);
}*/
#nav_menu li {
  -webkit-transition: all ease-out 150ms;
     -moz-transition: all ease-out 150ms;
}
/*#nav_menu li.active {
  color: rgba(255, 255, 255, .5);
}*/
#nav_menu a{text-decoration:none;}

#nav_home {margin-bottom:10px;}

#shareIconsDiv {
  position: fixed;
  top: 75px;
  right: -195px;
  z-index: 9000; 
  font-size: 15px;
/*  padding: 11px 11px;*/
  width: 33px;
}

.shareOpt {margin-left:10px;position:relative;}

.editOpt {margin-left:8px;position:relative;}

#shareIconsDiv.active {
  width: 171px;
  padding: 11px 17px;
}

#shareIconsDiv li.notactive{
  opacity:0;
  display:none;
}

.editOff {
  opacity:0;
  display:none;  
}

.editOn {
  opacity:.75;
  display:inline-table;  
}

#shareIconsDiv li#shareIconsDiv{
  font-size: 15px;
  margin-left: -22px;
}

#shareIconsDiv ul, #shareIconsDiv ul {
  position: relative;
  float: left;
  display: block;
  right: 0px;
/*  padding: 0px 12px;*/
  z-index: 110;
  cursor: pointer;
}

/*#shareIconsDiv ul{
  top: -11px;

}*/

#share_tools ul {
  top: 0px;
  width: 275px;
}

#shareIconsDiv li{
  display: block;
  float: left;
  padding: 8px 10px 12px 10px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.sitePanelShare li{
  padding: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  display: inline-table;
  position: relative;
  font-size: 12px;
  border-radius: 50px;
  margin: 2px 3px 0 3px;
  text-align: center;
  cursor: pointer;

   box-shadow: 0 0 0 2px rgba(0, 184, 213, 1) inset, 
              0 0 0 2px rgba(0, 184, 213, .5) inset,
              0 0 20px 0 rgba(0, 184, 213, 0) inset;
 -webkit-transition: all ease-out .15s;
     -moz-transition: all ease-out .15s;
  background: none;
  background: rgba(0, 184, 213, 0);
}

.sitePanelShare2 li > i{margin-top:3px;}

.sitePanelShare2 li{
  padding: 5px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: inline-table;
  position: relative;
  font-size: 16px;
  border-radius: 50px;
  margin: 2px 3px 0 3px;
  text-align: center;
  cursor: pointer;

/*   box-shadow: 0 0 0 2px rgba(0, 184, 213, 1) inset, 
              0 0 0 2px rgba(0, 184, 213, .5) inset,
              0 0 20px 0 rgba(0, 184, 213, 0) inset;*/
 -webkit-transition: all ease-out .15s;
     -moz-transition: all ease-out .15s;
  background: #FFF;
}

.share_urldiv{margin:15px 0px 10px}

@media (max-width : 1200px){
  #share_tools ul {padding: 0px;margin:0px;}
  .share_urldiv{margin:15px 0px 0px}
}


/* @media (min-width : 320px) and (max-width : 639px) {

  #shareIconsDiv {
    right: -236px;
    font-size: 13px;
  }

  #shareIconsDiv li#share_icon {
    font-size: 13px;
  }

  #shareIconsDiv li {
    padding: 7px;
    width: 12px;
    height: 12px;
  }

  #nav_toggle {
    width: 12px;
    height: 12px;
    font-size: 13px;
    padding:7px;
  }

  #nav_toggle div {
    margin-top: 2px;
  }
  #share_tools{margin-top:25px;}
  #share_tools ul {padding: 0px;}
} */

/*.xlsx_table,.xlsx_table th,.xlsx_table td {
  border-collapse:collapse;
}*/