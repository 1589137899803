/* Common.css - styles used by common components, popups, etc */

div {
    box-sizing: border-box;
}

.video-js .vjs-big-play-button {
    left: 48%;
    top: 48%;
}

.video-js {
    max-height: 500px;
}

.dropdown-menu li,#user_menu li, #share_menu li{color:#333;font-size:14px;padding:5px 20px 5px 20px;cursor:pointer;line-height: 120%;text-align:center;}
    
#nav, .close {
	position: relative;
	width: 25px;
    height: 25px;
	z-index: 105;
    cursor: pointer;
    font-size: 12px;
}

#nav div, .close div {
  	-webkit-transition: all ease-in-out 150ms, width ease-in-out 150ms, top ease-in 30ms, left ease-in 110ms;
    -moz-transition: all ease-in-out 150ms, width ease-in-out 150ms, top ease-in 30ms, left ease-in 110ms;
	transition: all ease-in-out 150ms, width ease-in-out 150ms, top ease-in 30ms, left ease-in 110ms;
}

#nav.close div.one_one, #nav.close div.one_two, #nav.close div.three_one, #nav.close div.three_two, .close div.one_one, .close div.one_two, .close div.three_one, .close div.three_two {
	width: 6px;
}
#nav.close div.one_one, .close div.one_one {
	left: 10px; /* find wisp book box needed 9/12 */
	top: 13px;
	-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
			transform: rotate(45deg);
}
#nav.close div.one_two, .close div.one_two {
	left: 21px;
	top: 13px;
	-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
}
#nav.close div.two, .close div.two {
	opacity: 0;
  	-webkit-transition: all ease-out 90ms;
       -moz-transition: all ease-out 90ms;
     		transition: all ease-out 90ms;
}
#nav.close div.three_one, .close div.three_one {
	left: 10px;
	top: 24px;
	-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
}
#nav.close div.three_two, .close div.three_two {
	left: 21px;
	top: 24px;
	-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
			transform: rotate(45deg);
}

.close_pos_float_50{        /* inside div with padding 50 */
	float:right;
    margin: -30px -40px -6px 0px;
}

.close_pos_float_30, .close_pos_float_signin{        /* inside div with padding 30 */
	float:right;
    margin: -20px -20px -6px 0px;
}

.close_pos_float_30_2{        /* inside div with padding 30 */
	float:right;
    margin: -20px -20px 0px 0px;
}

.close_pos_float_25{        /* inside div with top padding 25 */
	float:right;
    margin: -17px -20px -6px 0px;
}

.close_pos_float_20{        /* inside div with padding 20 */
	float:right;
    margin: -10px -6px -6px 0px;
}

.close_pos_float_10{        /* inside div with padding 10 */
	float:right;
    margin: -8px -12px -6px 0px;
}

.acct_panel {
    width:600px;
    min-height:355px;
    vertical-align:top;
    background: rgb(238,238,238);
    color: #666;
    margin-bottom: 10px;
}

.acct_panel_inner {
    margin:0px 16px 20px 0px;
    padding: 20px;
}

#acct_location {
    width: 100%;
}

.stripe_status{margin:5px 0px 10px;font-size:12px;}

.acct_tile_body {
    margin-top: 15px;
}

.set_sect_stat{position: absolute;
    left: -40px;
    top: 0px;
    font-size: 25px;
    border: 1px solid rgb(237,237,237);
    width: 31px;
    height: 38px;
    text-align: center;
    padding-top: 4px;
}

.acctRow {
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
}

.acctCol1 {
    width: 250px;
    font-weight: 600;
}

.acctCol2 {
    width: 295px;
}

.acctCol1_indent {
    width: 150px;
    font-weight: 600;
    margin-top: 8px;
}

#passwordControls {
    display: none;
    padding-left: 10px;
}

.azureAuthMessage {
    font-size: 24px;
    text-align: center;
    margin: 150px auto;
    padding: 30px;
    width: 375px;
    height: 100px;
    color: #333;
    background: #EEE;
}
.popup_input {
	width: 100%;
	margin: 25px 0px 10px;
}

.wt_inputdiv, div.te_textareadiv {
	position: relative;
	padding: 0px;

	cursor: text;
	box-shadow: none;
	outline: none;
	border-radius: 0px;
	border: none;

  	-webkit-transition: all ease-out 50ms;
       -moz-transition: all ease-out 50ms;
     		transition: all ease-out 50ms;
}

#signin_title {
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.wt_textarea {
	position: relative;
	z-index: 2;
	height: 120px;
	line-height: 16px;
	width: 100%;
	margin: 0px;
    font-size: 15px;
    padding: 6px 10px;
    box-sizing: border-box;
	outline: none;
	border-radius: 0px;
    border: none;
    background: white;
    color: #333;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, .25) inset;

  	-webkit-transition: all ease-out 50ms;
       -moz-transition: all ease-out 50ms;
     		transition: all ease-out 50ms;
}

.wt_input{
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 15px;    /* smaller and iPhone will zoom in on focus so increase font size on mobile - zoom makes things not look right */
	line-height: 16px;
	font-weight: normal;
    font-weight: 400;
	position: relative;
    z-index: 2;
  
	width: 100%;
	height: 30px;
    color: #333;
    background: #FFF;
	border-radius: 2px;
	margin: 0;
	padding: 2px 10px;
	box-sizing: border-box;
}

.accountContainer {
    padding: 20px 0px 10px 0px;
    margin:auto;
}

.popClass {
    margin: 0px 0px 10px 0px;
    padding: 5px;
    font-size: 12px;
    text-transform: uppercase;
    color: #0645AD;
    font-weight: 600;
    cursor: pointer;
}

.folderClass {
    color: rgb(243,218,89);
}

.panel_name{font-style:italic;font-size:24px;font-family: "Surveyor SSm A", "Surveyor SSm B","Open Sans", Helvetica;font-weight: 400;}
.publishCert {
    font-size: 13px;
}

#signin_div, #remove_site_div, .popupWrapper, .createAcctDiv, .sharePopup {
    background: #FFF;
    padding: 30px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50px;
    z-index: 110003; /* greater than fullscreen_popup_sub */
    -webkit-overflow-scrolling: touch;
    -webkit-transition: all ease-out 290ms;
    -moz-transition: all ease-out 290ms;
    transition: all ease-out 290ms;
    /* border: 1px solid #EEE; - this screws up width */
    max-height: 700px;
    overflow-y: auto;
}
#signin_div, #remove_site_div, .popupWrapper, .createAcctDiv {
    width: 360px;
    top: 70px;
}
.sharePopup {
    width: 700px;
    top: 155px;
}
#supp_div {
    background: #FFF;
    padding: 50px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 180px;
    width: 475px;
    z-index: 100003; /* greater than fullscreen_popup_sub */
    -webkit-overflow-scrolling: touch;
    -webkit-transition: all ease-out 290ms;
    -moz-transition: all ease-out 290ms;
    transition: all ease-out 290ms;
    border: 1px solid #EEE;
}

.vip_doc_item, .diy_doc_item {
    background: #FFF;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-left: 1px solid #AAA;
    border-right: 1px solid #AAA;
    border-bottom: 1px solid #AAA;
    text-align: left;
}

.addDocTitle {
    margin-top: 0px;
    font-size: 18px;
    text-transform: uppercase;
}

.addContentDiv {
    padding: 10px;
    margin-top: 30px;
    border: 1px solid rgb(0,0,0,.3);
    background: white;
    position: relative;
}

.storyPickerPopup {
    background: rgb(240,240,240);
}
.fullscreen_back, .fullscreen_back2, .fullscreen_back3{position:fixed;top:60px;left:0px;width:100%;height:100%;background:rgba(43, 43, 43, 0.6);}
.fullscreen_back{z-index:500;}
.fullscreen_back2{z-index:1001;}
.fullscreen_back3{z-index:1001;}
.fullscreen_pos_50{
	left:25%;
	top:160px;
}
.fullscreen_pos_20px{
	left:10px;
	top:10px;
}
.subscribe_popup {
    background: #fff;
	position: absolute;
	width: 700px;
	z-index: 110002;          /* greater than dollar_wisp 11001 */
	overflow-y: auto;
  		-webkit-overflow-scrolling: touch;
}
.subscribe_popup_padding {
	padding: 60px 50px;
}
.wispPromo {
    background: #059a05;
    color: white;
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding:10px 5px;
    cursor: pointer;
    /* position: absolute;
    bottom: 40px; */
    margin-top: 200px;
}

.fullscreen_popup_sub {
	padding: 45px 50px 25px;
    background: #fff;
	position: absolute;
	width: 700px;
	z-index: 110002;          /* greater than dollar_wisp 11001 */
	
  	-webkit-transition: all ease-out 290ms;
       -moz-transition: all ease-out 290ms;
             transition: all ease-out 290ms;
}

.pickerTitleDiv {
    margin-bottom:40px;
}
.pickerTitle {
    margin-bottom:10px;
}
.pickerSubTitle {
    margin-bottom:30px;
}

.siteDateDiv {
    display: inline-table;
    vertical-align: top;
    font-size: 12px;
    width: 95px;
    margin-right: 10px;
    margin-top: 3px;
}

.webt_ui_dashboard {
    width: 100%;
    height: 100%;
    background: #FFF;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
}

.wispNavFolder {
    margin-right: 5px;
    color: rgb(221, 221, 0);
}

.main_pan_item.shift_item {
    margin-left: 15px;
}

.nextDocLink {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: rgb(40 154 255);
    margin: 0px 25px 50px 0px;
}

.nextDocName {
    font-size: 36px;
    color: rgb(40 154 255);
}

#youtube_div {
    width: 600px;
}

.addItemsDiv {
    display: flex;
    width: 100%;
}

.addItemsDiv .addItem {
    margin-top: 20px;
    width: 170px;
    margin-left: 20px;
}

.docxMathPara {
    display: flex;
}

.docxLimLum {
}

.docxLimLume {

}

.docxLimLumelim {
    font-size: .6em;
    text-align: center;
}

.docxBarCont {

}

.docxBarBottom {
    height: 30px;
    border-top: 1px solid #555;
}

.docxBarTop {
    height: 30px;
    border-bottom: 1px solid #555;
}

.docxBare {

}

#decisionDiv {
    padding: 25px 30px 30px 30px;
    position: fixed;
    top: 35%;
    margin:auto;
    z-index: 10002;
    font-size:16px;font-weight:bold;
    border:solid 1px;
    max-width: 800px;
}
#decisionDiv a{text-decoration:none;}
.decisionMesP {margin: 10px 0px 30px;}

.addNoteCont {
    display: flex;
    margin-top:10px;
}

.noteContainer {
    width: 1200px;
    margin: 0px auto 0px;
    display: flex;
}

.noteSubContainer {
    display: flex;
}

.noteTitle {
    font-size: 16px;
    margin-top: 7px;
}

.noteToolBar {
    display: flex;
    font-size: 16px;
    color: white;
    /* background: #4D4D4DB3; */
}

#noteArea, #captionArea, #authNoteArea {
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 18px;
}

#authNoteArea {
    width: 500px;
}

.noteAreaSmall {
    height: 35px;
    margin-top: 5px;
}

.noteAreaBig {
    height: 80px;
    width: 600px;
    margin-top: 5px;
    margin-right: 5px;
}

.noteAreaDiv, .authNoteDiv {
    margin-right: 0px;
}

.authorSubmitButton {
    margin:0px 50px 0px 0px;
}

.addNoteButDiv {
    width: 170px;
    margin-right: 20px;
    margin-top: 10px;
}

.catSectionDiv {
    margin-top: 50px;
}

.accountWrapper {
    vertical-align: top;
    padding: 60px 20px;
}

.page_ex_cont_div{
    position: relative;
    width: 600px;
    margin: 60px auto;
    padding-top: 70px;
}

.feature_pan_title {
    font-size: 24px;
}
.feature_pan_subtitle {
    font-size: 15px;
    text-align: center;
    margin: 10px 0px 50px 0px;
}

.settings_heading {
    width: 100%;
    line-height: 40px;
    background: rgb(138,138,138);
    color: #FFF;
    text-transform: uppercase;
    font-size: 14px;
    padding-left: 25px;
    display: flex;
}

.settings_subheading {
    font-size: 14px;
    padding: 0px 0px 10px;
}

.vip_circle{margin-right:10px;cursor:pointer;padding:3px;min-width: 20px;}

.radioContainer {
    display: flex;
}
.radioButton {
    width: 200px;
    height: 40px;
    border: 1px solid #888;
}

.videoPlayerCont {
}

.noteSendIcon {
    color: white;
    margin: 12px 0px 0px 20px;
    font-size: 24px;
    cursor: pointer;
}


.authorIcon {
    margin: 10px;
    cursor: pointer;
    font-size: 24px;
}
.authorIconAdjust {
    margin-top: -5px;
}
.noteEditCont {
    width: 800px;
}
.noteEditToggle {
    margin: -20px 27px;
}

.wisp_app_list {
    background: rgb(247,247,247);
    display: block;
    padding: 25px;
}

.tengine_button {
    display: flex;
    box-sizing: border-box;
    text-align:center;
    cursor:pointer;
    letter-spacing: 0;
    padding: 10px 30px; /* need padding on right so room for spinner */
    font-family: Arial;
    position: relative; /* needed for spinner */
}

.radioAccountButton {   /* put this after tengine_button */
    width: 120px;
    height: 39px;
    padding: 0px;
    font-size: 13px;
}

.buttonIconDiv, .buttonHintDiv {
    vertical-align: middle;
}
.buttonIconDiv {
    margin-right: 10px;
}

.accountSigninCont {
    margin: 15px 0px 50px;
}

.uploaderCont {
    margin: 6px 5px 5px 50px;
    display: flex;
}

.gwisp_command{
    font-size: 13px;
    cursor: pointer;
    text-align: left;
    text-transform: uppercase;
    padding: 4px 10px;
}
.gwisp_command:hover{
  background:rgb(240,240,240);
  color:#000;
}

.trackingSection {
    font-size: 20px;
    margin:30px 0px 10px;
}

.inReachInfoDiv {
    margin-left: 5px;
    cursor: pointer;
}

.book_list_action {
    display: inline-table;
    margin-right: 25px;
    cursor: pointer;
}

.book_list_action_container {
    background: #F0F0F0;
    width: 100%;
    padding: 15px 20px;
    margin-top: 10px;
}

.te_caption {
    max-width: 600px;
    margin: auto;
    padding: 10px 15px;
    background: rgb(242 239 239);
    border-radius: 5px;
    margin-top: 10px;    
}

.firstButton {
    margin-left:15px;
}

.mapGroup {
    background: rgb(218, 218, 60);
    color: black;
    cursor: pointer;
    position: relative;
}

.mapGroupClosed {
    width: 35px;
    border-radius: 20px;
    padding: 10px 1px 10px 12px;
    margin-left: -17px;
    margin-top: -17px;
}

.mapGroupOpen {
    border-radius: 3px;
    width: 250px;
    margin-left: -35px;
    margin-top: -15px;
    padding: 1px 4px;
}

.shareDiv {
    padding: 10px;
    background: #c9e9c9;
    display: flex;
}
.referralDiv {
    padding: 30px;
    background: #c9e9c9;
    display: flex;
}
.referralPanel1, .referralPanel2 {
    padding: 20px;
}
.referralPanel1 {
    width: 50%;
}
.referralPanel2 {
    width: 50%;
}
.referralLinkDiv {
    display: flex;
}
.sharePanel {
    padding: 5px 20px;
}
.referralHeading {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}
.referralBody {
    font-size: 12px;
}

.referralHint {
    color: #777;
    font-size: 13px;
    margin-bottom: 5px; 
}

.referralLinkCont, .shareLinkCont {
    border: 1px solid #888;
    background: #EEE;
    padding: 5px 8px;
    margin-right: 10px;
}

.shareLinkCont {
    width: 450px;
    overflow: hidden;
}

.referralCont {
    margin-top: 60px;
}

.postLinkContainer {
    display: flex;
}

.buttonGroup {
    display: flex;
}

#webt_ui_account {
    vertical-align: top;
    padding: 80px 20px;
}

.ui_obox{ /*ui outer box*/
    display:inline-table;
    position:relative;
    width:100%;    
}  

.btn{display:inline-block;padding:8px 12px 2px;margin-bottom:0;font-size:12px;text-align:center;vertical-align:middle;cursor:pointer;color:#FFFFFF;height:20px;} /* background-color:#0aadff; */

.btn-warning.active,.btn-danger.active,.btn-success.active,.btn-info.active,.btn-inverse.active{color:rgba(255, 255, 255, 0.75);}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active,.btn-primary.active,.btn-primary.disabled,.btn-primary[disabled]{background-color:#0aadff;}

.subscribe_div {
    display: flex;
}

.expiredText {
    float: right;
    margin-right: 25px;
    margin-top: 15px;
    font-size: 11px;
}

.close_float_caption{        /* inside div with padding 20 */
    float:right;
}

.docContainer {
    margin-top: 60px;
}

.acceptTermsDiv {
    margin-top: 25px;
}

.accordian {
    float:right;
    margin-right: 20px;
    font-size: 18px;
}

.input_active_hint {
	position: absolute;
	left: -10px;
	opacity: 0;
	color: #444;

  	-webkit-transition: all ease-out 50ms;
       -moz-transition: all ease-out 50ms;
     		transition: all ease-out 50ms;
}
div.input_hint {
	position: absolute;
	left: 18px;
	top: 0px;
	line-height: 40px;
	opacity: 1;
	z-index: 1;
	color: #666;
}
/* this is lame - see .input_active_hint above */
div.input_active_hint {
	top: -19px; /* this shouldn't be separate from font size of hint */
	font-size: 12px;
	line-height: 15px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .08em;
	word-spacing: .08em;
}

.wt_inputdiv.active div.input_active_hint, div.te_textareadiv.active div.input_active_hint {
    opacity: 1;
	left: 0px;
	color: rgba(64, 64, 64, 1);

  	-webkit-transition: all 150ms ease-out 400ms;
       -moz-transition: all 150ms ease-out 400ms;
     		transition: all 150ms ease-out 400ms;
}
.wt_inputdiv.active div.input_hint, div.te_textareadiv.active div.input_hint {
    opacity: 0;
    left: 40px;
}

.wt_inputdiv{height: 40px;}

.incCont {
    display:flex;
}

.incIncCont {
    margin-left: 5px;
    cursor: pointer;
    height: 15px;
    vertical-align: top;
}
.incIcon {
}

.book_icon {
    background:rgb(77,77,77);
    color:#FFF;
    margin: 0px 20px 20px;
    padding: 40px;
    width: 200px;
    text-align: center;
	-webkit-box-shadow:0 0 10px rgba(77,77,77,.5); 
	-moz-box-shadow: 0 0 10px rgba(77,77,77,.5); 
	box-shadow:0 0 10px rgba(77,77,77,.5);
	box-sizing: border-box;
}
.book_details_div {
    margin: 0px 20px 20px;
}

.wispSelectA {
    display:inline-block;
    margin-bottom:0;
    vertical-align:middle;
    font-size: 12px;
    padding:5px 12px;
    text-align:center;
    cursor:pointer;
    color:#FFFFFF;
    height:20px;
}

.removeUserDiv {
    margin: -40px 0px 40px 20px;
}

.removeUserLink {
    cursor: pointer;
}

.closeGray {
    color: rgb(128,128,128);
}

.settingsInput {
    width: 350px;
}

#new_lat_div, #new_long_div, #new_alt_div {
    margin-top: 35px;
}

.mapImage {
    object-fit: cover;
    max-height: 400px; /* need this to keep portrait images from being too big - not sure why I commented it out */
    margin-left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 860px) {

    .settingsInput {
        width: 200px;
    }

    .noteAreaBig {
        width: 280px;
    }

    #webt_ui_account {
        padding: 70px 10px;
    }

    .sharePopup {
        width: 100%;
        padding: 20px;
    }
    .postLinkContainer {
        display: block;
    }

    .crazyLinks {
        margin-left: 10px;
    }

    .referralDiv {
        display: block;
    }

    #noteArea, #captionArea {
        width: 550px;
    }
    .noteEditCont {
        width: 100%;
    }
}

@media (max-width: 480px) {
    
    .authorSubmitButton {
        margin:0px;
    }
    
    .subscribe_div {
        display: block;
    }
    .book_details_div {
        margin-top: 30px;
    }
        
    .wispSelectA {
        padding:5px 12px;
    }
    
    .btn{padding:7px 8px 3px;}

    .dropdown-menu li,#user_menu li, #share_menu li{font-size:12px;padding:5px 15px 5px 15px;}

    .subscribe_popup {
        width: 100%;
        left: 0px;
    }

    .ui_obox{ /*ui outer box*/
        display:block;
    }
    
    .settings_heading {
        padding-left: 20px;
    }

    .shareLinkCont {
        width: 325px;
    }
    .sharePanel {
        padding: 5px 0px;
    }
    .shareDiv {
        width: 100%;
    }
    .wispPromo {
        margin-top: 100px;
    }
    .referralLinkDiv {
        display: block;
    }
    .referralAction {
        margin-top: 10px;
    }
    
    .referralPanel1, .referralPanel2 {
        width: 100%;
        padding: 10px;
    }

    #captionArea {
        width: 100%;
    }
    .authorIconBut {
        margin-top: 0px;
    }

    .te_caption {
        max-width: 100%;
        padding: 0px;
        border-radius: 0px;
        margin-top: -5px;
    }    

    .close_float_caption{        /* inside div with padding 20 */
        margin: -14px -6px -6px 0px;
        padding-bottom: 7px;
    }
    
    .book_list_action_container {
        line-height: 30px;
    }
    .close_pos_float_signin{        /* inside div with padding 30 */
        margin: -15px -15px -6px 0px;
    }
    

    .wisp_app_list {
        padding: 10px 20px;
    }
    
    .noteEditCont {
        width: 100%;
    }
    
    .noteContainer {
        margin: 0px;
        width: 100%;
    }

    .videoPlayerCont {
    }
    
    .tengine_button {
        padding: 10px;
    }

    .radioAccountButton {   /* put after tengine_button so we get this padding for radio buttons */
        padding: 0px;
    }

    .radioButton {
        width: 100px;
        height: 60px;
    }

    .acctCol1 {
        width: 160px;
    }

    .acctCol2 {
        width: 200px;
    }

    .page_ex_cont_div{
        width: 375px;
    }

    .feature_pan_title {
        font-size: 16px;
    }
    .feature_pan_subtitle {
        font-size: 11px;
    }

    .accountWrapper {
        padding: 80px 0px 20px;
    }

    .catSectionDiv {
        margin-top: 20px;
    }
    
    /* .fullscreen_popup_sub {
        width: 375px;
    } */
    .fullscreen_popup_sub {
        width: 100%;
        left: 0px;
        transform: none;
        padding: 20px;
    }


    .fullscreen_pos_50{
        left: 0px;
        width: 100%;
    }
    .fullscreen_pos_20px{
        left:0px;
    }
        

    .nextDocLink {
        text-align: left;
        font-size: 15px;
        margin: 0px 0px 20px;
    }
    .nextDocName {
        font-size: 24px;
    }
        
    .noteAreaDiv {
        width: 100%;
        margin-right: 0px;
    }

    .authNoteDiv {
        margin-right: 0px;
    }

    #authNoteArea {
        padding: 5px;
        box-sizing: border-box;
        font-size: 18px;
        width: 300px;
    }

    #noteArea {
        padding: 5px;
        box-sizing: border-box;
        font-size: 18px;
    }
   
    #decisionDiv {
        padding: 20px;
        width: 100%;
        left: 0px;
    }

    .addItemsDiv .addItem {
        margin-left: 0px;
        margin-top: 10px;
        width: 100%;
    }
    
    .addItemsDiv {
        display: block;
    }    

    #youtube_div {
        width: 375px;
    }

    .acct_panel {
        width: 370px;
    }
    .acct_panel_inner {
        padding: 10px;
        margin: 0px;
    }

    #signin_div,#supp_div,.popupWrapper, .createAcctDiv{
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        top: 80px;
    }

    .webt_ui_dashboard {
        padding-left: 0px;
        padding-right: 0px;
    }

    .wt_input{
        font-size: 15px;    /* smaller and iPhone will zoom in on focus so increase font size on mobile*/
    }    

    .wisp_m_icon {
        position: absolute;
        top: -2px;
        left: 5px;
        z-index: 10;
        font-size: 24px;
        opacity: .15;
    }
    
    .wisp_guardian {
        position: fixed;
        z-index: 11001;     /* lower than header */
        width: 30px;
        height: 30px;
        top: 75px;
        margin: 0px 0 0 0px;
        border-radius: 50px;
        cursor: pointer;
        background: rgba(173,238,255,.8); /*rgba(4, 171, 255, .95);*/
        -webkit-transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
        -moz-transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
        transition: width ease-out 150ms, height ease-out 150ms, right ease-out 150ms, top ease-out 150ms;
    }

    .wisp_guardian_mobile {
        position: absolute;
        z-index: 11001;
        width: 30px;
        height: 30px;
        top: 57px;
        margin: 0px 0 0 0px;
        border-radius: 50px;
        cursor: pointer;
        background: rgba(173,238,255,.8);
    }

    /* #wisp_guardian.active {
        left: 5px;
        top: 5px;
        width: 25px;
        height: 25px;
        -webkit-transition: all ease-out 100ms;
        -moz-transition: all ease-out 100ms;
        transition: all ease-out 100ms;
    } */
    
    .wisp_guardian.open, .wisp_guardian_mobile.open {
        left: 205px;
    }

    .wisp_guardian.closed, .wisp_guardian_mobile.closed {
        left: 7px;
    }

    .privacyContainer, .termsContainer{
        margin-top: 60px;
    }

    .vjs_video_3-dimensions {
        width: 375px;
        height: 600px;
    }

    .video-js .vjs-big-play-button {
        left: 40%;
      
    } 
    .video-js {
        max-height: 250px;
    }
        
    .footer_column {
        margin-bottom: 30px;
    }

    .footer_column3 {
        width: 100%;
    }
}
  
  